@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://use.fontawesome.com/releases/v5.12.0/css/all.css ");
body,
* {
  font-family: "Inter", sans-serif !important;
  letter-spacing: normal !important;
}
body .MuiPickersPopper-root {
  z-index: 99999;
}
.apexchart {
  max-width: 650px;
  margin: 35px auto;
}
.fab {
  font-family: "Font Awesome 5 Brands" !important;
}
.loginBg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #f7fafc;
}
.loginBox {
  max-width: 496px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.loginBox img {
  margin-top: 96px;
  margin-bottom: 40px;
}
.loginBox .login {
  padding: 24px 32px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.12),
    0 15px 35px 0 rgba(60, 66, 87, 0.08);
  text-align: left;
}
.loginBox .login form + a {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: auto;
  font-size: 12px;
  margin-top: 10px;
}
.loginBox .login div,
.loginBox .login div label {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  color: #1a1f36;
  margin-bottom: 5px;
  position: relative;
  text-align: left;
}
.loginBox .login div span {
  position: absolute;
  right: 10px;
  top: 40px;
}
.loginBox .login div input {
  border: 1px solid #dedfe2;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  height: 48px;
  font-size: 16px;
  width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  margin-bottom: 20px;
}
.loginBox .login button {
  height: 48px;
  font-size: 16px;
  font-weight: 500;
}
.loginBox .login h4 {
  font-size: 24px;
  color: #1a1f36;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 10px;
}
.loginBox .login h6 {
  font-size: 14px;
  color: #1a1f36;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 24px;
}
.loginBtn a {
  color: #000;
  margin-top: 7px;
}
.loginBox .login div[class*="Error"] {
  display: inline-block !important;
  width: calc(100% - 32px);
  margin-bottom: 20px !important;
}
.loginBox .login div[class*="Error"] div {
  /* width:auto!important; */
  margin-bottom: 0px !important;
}
.loginBox .login div[class*="Error"] div:first-child {
  width: auto !important;
}
.loginBox .login div[class*="Error"] div:last-child {
  width: calc(100% - 35px) !important;
  line-height: 15px !important;
}
.avtarPopup div[class*="Popover"] {
  right: 95px !important;
  top: 60px !important;
  left: auto !important;
}
.addBrandPopup h2 {
  font-size: 20px;
  margin-bottom: 20px;
}
.addBrandPopup div[data-test="sentinelStart"] + div {
  display: inline-block;
  text-align: center;
  width: 100%;
  max-width: 450px;
  box-shadow: 0px 4px 5px 2px rgb(0 0 0 / 20%) !important;
  border: 1px solid #ddd !important;
  border-radius: 10px;
}
.addBrandPopup div button {
  margin-right: 10px;
}
.BrandPopupBox {
  display: block;
  max-width: 450px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  /* box-shadow:0px 4px 5px 2px rgb(0 0 0 / 20%) !important; */
  padding: 20px;
}
.BrandPopupBox h4 {
  font-size: 17px;
  margin-bottom: 10px;
}
.BrandPopupBox form div {
  width: 100%;
  margin-bottom: 10px;
  font-size: 12px;
}
.BrandPopupBox form div input {
  padding: 5px;
  border: 1px solid #ddd;
  font-size: 12px;
  margin-top: 5px;
}
button[class*="Primary"] {
  /* border-radius: 15px; */
  height: 30px;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #05169e;
  text-transform: none;
  box-shadow: 0px 3px 3px #ccc;
  background: #3c4fe0;
  color: #fff;
}
button[class*="Primary"]:hover {
  background: #05169e !important;
  color: #fff !important;
}
button[class*="outlinedSizeSmall"],
a[class*="outlinedSizeSmall"] {
  border-radius: 15px;
  height: 30px;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ddd;
  text-transform: none;
  box-shadow: 0px 2px 3px #ccc;
  background: #fff !important;
}
button[class*="outlinedSizeSmall"]:hover,
a[class*="outlinedSizeSmall"]:hover {
  background-color: #f2f2f2 !important;
}
button {
  text-transform: none !important;
}
.trending {
  padding-top: 48px;
}
.trending h4 {
  margin-bottom: 12px;
}
.trending h5 {
  margin-top: 10px;
  margin-bottom: 5px;
}
.trending h6 {
  margin-top: 0px;
  margin-bottom: 10px;
}
.trendingBtn {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 15px;
}
.trendingBtn a {
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #3b4edf;
  text-transform: none !important;
  box-shadow: 0px 3px 3px #ccc !important;
  background: #3b4edf;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  line-height: 40px;
}
.trendingBtn a:hover {
  background: #05169e !important;
  color: #fff !important;
}
.subscriptionIcon > button {
  margin-left: 29px;
  margin-top: 5px;
}
.subscriptionIcon > button svg {
  width: 22px;
  height: 22px;
}
.subscriptionIcon > button svg path {
  fill: #a6b2c1;
}
.loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0px;
  top: 0px;
  text-align: center;
}
.resultBox h5 {
  min-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  text-align: center;
}
.resultBox h5 + p {
  /* min-height: 50px; */
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: center;
  line-height: 17px;
}
.resultBox div + p {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: center;
  line-height: 17px;
  margin-bottom: 25px !important;
}
.resultBox > div > div {
  /* height: 38px; */
  /* overflow: hidden; */
  font-size: 14px;
  color: #6e788a;
  text-align: center;
}
header {
  background: #fff !important;
}
div {
  margin-top: 0px;
}

.searchBrandTab button {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  min-width: auto;
  margin-right: 28px;
  text-transform: none;
  font-size: 16px;
}
.searchBrandTab button[tabindex="0"] {
  font-weight: 600;
  color: #3c4fe0;
}
.searchBrandPaper {
  border: 1px solid #e3e8ee;
  border-radius: 4px;
  background-color: #fbfdfe !important;
  box-shadow: 0 2px 5px 0 rgba(60, 66, 87, 0.08) !important;
  padding: 24px;
}
.searchBrandPaper svg {
  width: 14px;
  height: 14px;
  fill: #c1c9d2;
}
.paperSelect > div {
  border: 1px solid #dedfe2 !important;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  height: 40px;
}
.paperSelect input {
  border: none !important;
}
.paperSelect > div div:after,
.paperSelect > div div:before {
  display: none;
}
.paperSelect div[aria-haspopup="listbox"] {
  height: 40px;
  padding-left: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 40px;
}
.paperSelect div[aria-haspopup="listbox"] + input + svg {
  position: relative;
  right: 5px;
  top: 0px;
}
.searchBrandPaper span[role="slider"][data-index="1"] {
  margin-left: -35px;
}
.searchBrandPaper > button {
  font-size: 16px;
  text-transform: none !important;
}
.searchBrandPaper > button svg {
  fill: #8792a2;
}
.searchBrandPaper hr + div > button {
  font-size: 14px;
  text-transform: none !important;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 500;
  color: #3c4fe0;
  border: 1px solid #3c4fe0;
}
.searchBrandPaper hr + div > button:hover {
  color: #fff !important;
  background: #3c4fe0 !important;
}
.searchBrandPaper hr + div > button + div button {
  font-size: 14px;
  text-transform: none !important;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
  box-shadow: none;
  font-weight: 500;
}
.searchBrandPaper hr + div > button + div button:first-child {
  color: #6e788a;
  border-color: #dedfe2;
}
.searchBrandPaper hr + div > button + div button:last-child {
  background-color: #3c4fe0;
}
div[role="region"][class*="Accordion"] form > div {
  max-width: 40%;
  width: 100%;
  margin-right: 60%;
}
div[role="region"][class*="Accordion"] form > div span {
  position: absolute;
  right: 12px;
  top: 42px;
}
div[role="region"][class*="Accordion"] form > div input {
  border: 1px solid #dedfe2;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
  height: 48px;
  font-size: 16px;
  width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  margin-bottom: 20px;
}
div[role="region"][class*="Accordion"] form > div label {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  color: #1a1f36;
  margin-bottom: 5px;
  position: relative;
}
span[class*="Slider-rail"] {
  /* position:relative; */
  background: #c1c9d2;
  opacity: 1;
}
span[class*="PrivateTabIndicator"] {
  background: #3c4fe0;
}
span[class*="Slider-rail"]:after {
  position: absolute;
  right: -33px;
  content: "100";
  width: 20px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #6e788a;
  top: -7px;
}
span[class*="Slider-rail"]:before {
  position: absolute;
  left: -23px;
  content: "0";
  width: 20px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #6e788a;
  top: -7px;
}
.countryAutoComplete {
  min-width: 180px !important;
  margin-left: 24px !important;
  border: 1px solid #dedfe2 !important;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  min-height: 40px;
}
.countryAutoComplete [class*="formControl"] {
  margin-top: 0px !important;
}
.countryAutoComplete label[class*="formControl"] {
  height: 40px;
  transition: none;
  transform: none;
  padding-left: 16px !important;
  line-height: 40px;
}
/* .countryRow > div
{

}*/
.tracker {
  width: calc(50% - 15px);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 50px;
}
.tracker h6 {
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
  margin-top: 10px;
}
.tracker h6 + div {
  margin-top: 0px;
  box-shadow: none;
  border: none;
}
.tracker table {
  height: 219px;
}
.tracker table[aria-aria-label="spanning table"] th {
  font-weight: bold;
}
.tracker table[aria-aria-label="spanning table"] td,
.tracker table[aria-aria-label="spanning table"] th {
  padding: 3px 5px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}
.tracker table[aria-aria-label="spanning table"] td > span {
  padding: 0px;
}
.tracker table[aria-aria-label="spanning table"] td > span svg {
  width: 15px;
  height: 15px;
}
.tracker table[aria-aria-label="spanning table"] tr:last-child td {
  font-weight: bold;
}
.tracker table[aria-aria-label="spanning table"] tr td:last-child {
  text-align: center;
  color: #00b437;
}
tr.others {
  /* height: 40px; */
  vertical-align: top;
}
.gaugeChart {
  max-width: 28%;
  flex-basis: 28%;
}
.gaugeChart > div > div {
  height: 165px;
}
.gaugeChart > div > div svg {
  max-width: 250px;
  margin-right: 15px;
  max-height: 100px;
}
.gaugeChart > div > div h6 + svg {
  height: auto;
  width: 25px;
}
.gaugeChart span[class*="gauge"] + div > div > div,
.gaugeChart span[class*="caption"] + div > div > div {
  max-width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.RVTracker {
  max-width: 68%;
  flex-basis: 68%;
  box-sizing: border-box;
}
.RVTracker .tracker {
  max-width: 100%;
  flex-basis: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
}
.Rvgraph {
  display: inline-block;
  /* background: #eee; */
  width: calc(50% - 20px);
  /* height: 450px; */
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-left: 11px;
  height: 250px;
  padding-left: 10px;
  padding-right: 10px;
}
.Rvgraph circle {
  /* fill:#597cb2 */
}
.Rvgraph svg g + path {
  stroke: #597cb2;
}
.Rvgraph svg g,
.Rvgraph svg line {
  opacity: 1 !important;
}
.countryRow .closeBtn svg {
  color: red;
  fill: red;
}
.countryAutoComplete label[class*="focused"],
.countryAutoComplete label[class*="filled"] {
  display: none;
}
.countryAutoComplete label + div:after,
.countryAutoComplete label + div:before {
  display: none;
}
.countryAutoComplete [class*="fullWidth"] {
  min-height: 40px;
  padding-right: 15px !important;
}
.countryAutoComplete input {
  padding-left: 16px !important;
  /* width:100% !important; */
}
.countryAutoComplete span[class*="Button-label"] {
  position: relative;
  top: 3px;
  right: 3px;
}
.countryAutoComplete button[class*="popupIndicatorOpen"] {
  position: relative;
  top: 3px;
  right: 3px;
  margin-right: 0px !important;
}
.countryAutoComplete button[title="Clear"]:hover {
  background: transparent;
}
.countryAutoComplete button[title="Open"]:hover {
  background: transparent;
}
.searchBox {
  height: 40px;
  width: 400px;
  border: 1px solid #dedfe2;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(98, 100, 167, 0.15);
  margin-top: 10px !important;
}
.searchBox div {
  margin-top: 0px !important;
}
.searchBox label + div:after,
.searchBox label + div:before {
  display: none;
}
.searchBox label[class*="focused"],
.searchBox label[class*="filled"] {
  display: none;
}
.searchBox label {
  transition: none;
  transform: none;
  padding-left: 42px;
  height: 40px;
  line-height: 40px;
}
.searchBox input {
  height: 40px;
  padding: 0px !important;
  padding-left: 12px !important;
}
.searchBox div[class*="positionStart"] svg {
  width: 22px;
  fill: #8792a2;
  position: relative;
  top: 9px;
  left: 16px;
}
.searchBox div[class*="positionStart"]::after {
  position: absolute;
  width: 10px;
  height: 5px;
  background: #fff;
  content: "";
  left: 32px;
  bottom: 10px;
}
.loginBtn {
  padding-top: 5px;
}
.hmenu > div {
  box-shadow: -6px 0px 14px 3px rgb(0 0 0 / 30%);
}
.hmenu > div > div button {
  margin-top: 5px;
  margin-right: 5px;
}
#brandTable h4 span {
  font-size: 28px;
  font-weight: normal;
  padding-left: 5px;
}
#brandTable div h4 + div a {
  border: 1px solid #dedfe2;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  padding-left: 13px;
  padding-right: 10px;
  text-transform: none;
  margin-left: 10px;
}
#brandTable div h4 + div button {
  border: 1px solid #dedfe2;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  padding-left: 13px;
  padding-right: 10px;
  text-transform: none;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  margin-left: 10px;
}
#brandTable div h4 + div > button:hover {
  /* background: #3C4FE0;
  border-color: #3C4FE0; */
  /* color: #fff; */
}
#brandTable div h4 + div button.selectedBtn {
  background: #3c4fe0 !important;
  border-color: #3c4fe0;
  color: #fff !important;
}
#brandTable table th {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #6e788a;
  padding-left: 5px;
  padding-bottom: 10px;
  cursor: pointer;
}
#brandTable table th:nth-child(5) {
  white-space: nowrap;
  min-width: 250px;
}
#brandTable table th:nth-child(6) {
  white-space: nowrap;
  /* min-width: 400px; */
}
#brandTable table th span {
  white-space: nowrap;
}
#brandTable div[class*="grid-xs-12"] {
  position: relative;
}
.brandAvatar div[class*="circular"] {
  width: 26px;
  height: 26px;
  border: 1px solid #e3e8ee;
  border-radius: 50%;
}
.brandAvatar div[class*="circular"] + strong {
  min-width: 160px;
  font-size: 16px;
  font-weight: 600;
  color: #1a1f36;
}
#brandTable tbody div[class*="valueChip"],
#brandTable tbody div[class*="jss37"] {
  min-width: 40px;
  height: 26px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
}
#brandTable tbody td {
  padding-bottom: 3.5px;
  padding-top: 3.5px;
  min-height: 56px;
}
#brandTable tbody td:first-child {
  padding-left: 5px;
}
#brandTable tbody tr td:nth-child(4) div {
  position: relative;
  margin-right: 20px;
}
#brandTable tbody tr td:nth-child(4) {
  padding-right: 100px;
  white-space: nowrap;
}
#brandTable tbody tr td:nth-child(4) > div::before {
  position: absolute;
  content: "";
  height: 32px;
  width: 1px;
  background-color: #e3e8ee;
  right: -70px;
}
#brandTable tfoot {
  background-color: #f7fafc;
  /* height: 56px; */
  /* border-top:1px solid #E3E8EE; */
}
#brandTable tfoot div[class*="spacer"] {
  display: none;
}
#brandTable tfoot td > div {
  margin-right: auto;
  position: absolute;
  width: calc(100% - 24px);
  left: 0px;
  background-color: #f7fafc;
  height: 56px;
  border-top: 1px solid #e3e8ee;
  bottom: -58px;
}
#brandTable tfoot td > div div[class*="action"] {
  display: none;
}
#brandTable tfoot div[class*="spacer"] + p {
  position: absolute;
  left: 125px;
  font-size: 14px;
}
#brandTable tfoot div[class*="selectRoot"] + p {
  position: absolute;
  font-size: 14px;
  color: #6e788a;
}
#brandTable tfoot div[class*="selectRoot"] {
  position: absolute;
  left: 225px;
  font-size: 14px;
  color: #6e788a;
}
#brandTable tfoot div[class*="selectRoot"] > div {
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dedfe2;
  border-radius: 4px;
  color: #6e788a;
  padding-top: 5px;
  padding-bottom: 5px;
}
/* .BrandPagination 
{
  position:absolute;
  right:8px;
  display: inline-flex;  
  bottom: -47px;
  line-height:28px;
}
.BrandPagination div::after, .BrandPagination div::before
{
  display:none;
}
.BrandPagination div[aria-labelledby="page-select"]
{
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.08);
  border: 1px solid #DEDFE2;
  border-radius: 4px;
  color: #6E788A;
  padding-top:3.5px;
  padding-bottom:3.5px;
  min-width:28px;
  padding-left:10px;
   font-size:14px; 
   color: #6E788A;
}
.BrandPagination label#page-select
{
  line-height:28px;
  color: #6E788A;
  letter-spacing: 0.25px;
  font-size: 14px;
  padding-right:5px;
}*/
.BrandPagination nav li button {
  border: none;
  border-radius: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
  background: transparent !important;
  color: #000 !important;
}
/* 
.BrandPagination nav li button[class*="selected"]
{
  color: #3C4FE0;
  background:transparent!important;
  font-weight: 600;
  position:relative;
}
.BrandPagination nav li button[class*="selected"]::before
{
  position:absolute;
  content:"";
  border-top:2px solid #3C4FE0;
  width:100%;
  height:1px;
  top: -14px;
  left:0px;
} */
.searchHead h4 {
  font-size: 32px !important;
  color: #1a1f36;
}
.searchHead + div button {
  border: 1px solid #dedfe2;
  border-radius: 6px;
  background-color: #ffffff;
  height: 40px;
  font-size: 14px;
  color: #1a1f36;
  text-transform: none;
  font-weight: normal;
  padding: 15px;
}
.searchHead + div button[class*="Primary"] {
  border-radius: 6px;
  color: #ffffff;
  background-color: #3c4fe0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  font-weight: 500;
}
.resultBox {
  padding-right: 16px !important;
}
.resultBox h5 + p + div div[class*="Chip"] {
  background-color: #f0f2f5;
}
.resultBox div[class*="Chip"] {
  padding: 1px 8px 5px 8px;
  border-radius: 4px;
  /* background-color: #F0F2F5; */
  font-size: 12px;
  height: 24px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 22px;
}
.resultBox p + div div div[class*="Chip"] {
  padding: 1px 8px 5px 8px;
  border-radius: 4px;
  /* background-color:#CBF4C9; */
  font-size: 20px;
  height: 32px;
  min-width: 50px;
  width: auto;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 32px;
  margin-bottom: 5px;
}
.resultBox p + div div div[class*="Chip"] + h6 {
  font-size: 12px;
}
.resultBox div[class*="Chip"] span {
  padding-right: 0px;
  padding-left: 0px;
  font-size: 12px;
  white-space: normal;
}
.resultBox p + div div div[class*="Chip"] span {
  font-size: 20px;
  white-space: normal;
  padding-right: 0px;
  padding-left: 0px;
}
.productTabResult {
  margin-left: 2px !important;
  position: relative;
  width: 100%;
}
.productTabResult .proResult div {
  margin-left: 0px;
  margin-right: 13px;
  margin-bottom: 20px;
  height: auto;
}
.productTabResult .proResult:nth-child(4) div {
  /* margin-right: 0px !important; */
}
.productTab h5 span {
  font-size: 28px;
  font-weight: normal;
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.54);
}
.sortProduct {
  margin-left: 10px;
  margin-bottom: 10px;
}
.sortProduct [class*="fullWidth"] {
  min-height: auto !important;
}
.sortProduct label {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}
.sortProduct > div {
  margin-top: -5px;
  min-height: auto !important;
  margin-left: 15px !important;
}
.sortProduct > div span {
  font-size: 12px;
}
.sortProduct .countryAutoComplete div {
  min-height: auto;
}
.sortProduct .countryAutoComplete label[class*="formControl"] {
  height: 30px;
  line-height: 30px;
}
.sortProduct .countryAutoComplete span[class*="Button-label"] {
  top: 0px;
}
.boxFooter button {
  width: 16px;
  height: 16px;
  padding: 0px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  overflow: hidden;
}
.boxFooter button span,
.boxFooter button span a {
  width: 19px;
  height: 19px;
}
.boxFooter button svg {
  width: 19px;
  height: 19px;
  fill: #8792a2;
  border-radius: 50%;
}
.boxFooter button:nth-child(2) svg {
  width: 23px;
  height: 23px;
}
.boxFooter button:nth-child(2) span {
  position: relative;
  right: 3px;
  top: -2px;
}
.boxFooter {
  height: auto !important;
  position: absolute;
  bottom: 16px;
  width: 100%;
  text-align: center;
}
.coverImg {
  background: #eee;
  height: 280px;
  overflow: hidden;
}
.coverImg img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.avatarImg {
  width: 136px !important;
  height: 136px !important;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  top: -20px;
  border: 4px solid #fff;
}
.avatarImg img {
  border: 1px solid #eee;
  width: calc(100% - 2px) !important;
  height: calc(100% - 2px) !important;
  box-shadow: 0 0 1px #fff inset;
  border-radius: 50%;
}
.brandTitle h4 {
  font-size: 32px;
  font-weight: bold;
  color: #1a1f36;
}
.brandTitle h6 {
  font-size: 16px;
  color: #6e788a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.brandTitle div[class*="brandTags"] div {
  height: 24px;
  border-radius: 4px;
  background-color: #f0f2f5;
  margin-top: 0px !important;
  font-size: 12px;
  color: #3c4257;
}
.productBox {
  margin-left: 0px !important;
  margin-right: 16px !important;
  padding: 0px !important;
  margin-bottom: 16px !important;
}
.productBox div[class*="Avatar-square"] {
  height: 245px;
  background: #fff;
  border-radius: 10px;
}
.productBox div[class*="Avatar-square"] img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.productBox > div {
  position: relative;
}
.productBox > div:hover {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.productBox h6 span {
  max-width: calc(100% - 20px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.productBox h6 {
  font-size: 12px;
  font-weight: bold;
}
.productBox h6[class*="subtitle2"] {
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px !important;
  line-height: 15px;
  height: 30px;
}
.productBox h6[class*="subtitle1"]:last-child {
  font-size: 18px;
  color: #1a1f36;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px !important;
}
div[aria-label="Brand tabs"] button {
  color: #6e788a;
  font-size: 16px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 24px;
  height: 56px;
  border-color: #e3e8ee !important;
}
div[aria-label="Brand tabs"] button[class*="selected"] {
  font-weight: 600;
  color: #3c4fe0;
}
.productTab {
  margin-top: -10px !important;
}
.productTab button[class*="outlined"] {
  margin-top: 12px;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-color: #e3e8ee;
}
.shareBtn {
  padding-left: 5px !important;
  padding-right: 10px !important;
  color: #6e788a;
  font-size: 14px;
  font-weight: 500;
}
.shareBtn img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.productTab .starBtn {
  min-width: 32px;
  padding: 0px;
  height: 32px;
  margin-right: -8px;
}
.productTab .starBtn svg {
  width: 14.5px;
}
.productDpdwn > div {
  border: 1px solid #dedfe2;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px;
  color: #1a1f36;
}
.productDpdwn fieldset {
  border: none;
}
.hoverIcon {
  position: absolute !important;
  width: 45px !important;
  height: 45px !important;
  right: 0px;
  top: 0px;
  z-index: 9;
  cursor: pointer;
}
.hoverIcon img {
  width: 100%;
}
img[alt="Download"] {
  margin-left: 8px;
  height: 15px;
}
img[alt="Calendar"] {
  margin-right: 8px;
}
img[alt="Settings"] {
  width: 14px;
  height: 14px;
  fill: #8792a2;
  margin-top: 3px;
}
img[alt="Settings"] + label div::after,
img[alt="Settings"] + label div::before {
  display: none;
}
img[alt="Settings"] + label {
  width: 100%;
  /* min-width: 100px; */
}
img[alt="Settings"] + label > div {
  border: none;
  width: 100%;
}
img[alt="Settings"] + label div,
img[alt="Settings"] + label div:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer;
}
img[alt="Settings"] + label > div > div {
  width: 100%;
  min-height: auto;
  height: 22px;
}
img[alt="Settings"] + label > div > div:hover,
img[alt="Settings"] label + span > div > div:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
img[alt="Settings"] + label > div > div div {
  height: 22px;
  line-height: 23px;
  transform: none;
  position: relative;
  top: -0.5px;
  left: -1px;
  width: 60px;
  overflow: visible;
}
img[alt="Settings"] + label > div > div div:last-child {
  display: block;
}
img[alt="Settings"] + label > div > div div:last-child div[class*="indicator"] {
  display: none;
}
.brands svg {
  width: 15px;
  height: 15px;
  top: 8px;
  right: 3px;
}
.settingsPop div[class*="Input"] {
  opacity: 0;
}
.settingsPop {
  height: 20px;
}
.settingsPop div[class*="menu"],
.settingsPop div[class*="menu"] div {
  width: 225px;
  height: auto;
  z-index: 99;
  background: #fff;
  border-radius: 4px;
}
.settingsPop div[class*="menu"] div div {
  background: #fff;
}
.settingsPop input {
  opacity: 0 !important;
}
.settingsPop div[class*="menu"] > div > div:first-child {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: #6e788a;
  margin-top: 10px !important;
}
.settingsPop div[class*="menu"] {
  position: absolute;
  border: 1px solid #e8e9ec !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
  left: -70px;
  top: 35px;
}
.settingsPop div[class*="menu"]:hover {
  border: 1px solid #e8e9ec !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
}
.settingsPop div[class*="menu"] > div > div {
  position: relative;
}
.settingsPop div[class*="menu"]::before {
  content: "";
  width: 250px;
  height: 40px;
  position: absolute;
  /* background:#ccc; */
  left: 0px;
  top: 0px;
  display: block !important;
  cursor: text;
  z-index: 999;
}
.settingsPop div[class*="menu"]::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  left: 38%;
  transform: rotate(45deg);
  top: -8px;
  display: block !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
  z-index: -1;
}
.settingsPop div[class*="menu"] > div > div {
  font-size: 16px;
  color: #1a1f36;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 500 !important;
  height: 32px;
  text-transform: none;
  cursor: pointer;
}
.sortStyle {
  height: 24px;
  white-space: nowrap;
  position: relative;
}
.sortStyle > div > span {
  position: relative;
}
img[alt="Settings"] + label > div > div::after {
  position: absolute;
  left: -20px;
  top: 0px;
  width: 100%;
  content: "";
  height: 100%;
  display: block !important;
}
.sortHead div {
  position: relative;
}
.sortStyle svg {
  opacity: 0 !important;
}
.sortHead span.sortUp {
  top: 3px;
  right: -21px;
}
.sortHead span.sortDown {
  top: 13px;
  right: -20px;
}
.sortHead span.sortUp,
.sortHead span.sortDown {
  position: absolute;
  width: 15px;
  height: 8px;
  opacity: 1;
}
.sortHead span.sortUp:hover,
.sortHead span.sortDown:hover {
  /* background: #f2f2f2; */
  opacity: 0.5;
}
.sortHead span.sortDown::after {
  width: 0px;
  height: 0px;
  border-left: 3.5px solid transparent;
  border-right: 3.5px solid transparent;
  border-top: 3.5px solid #8792a2;
  content: "";
  position: absolute;
  right: 3px;
  top: 1px;
}
.sortHead span.sortUp::before {
  width: 0px;
  height: 0px;
  border-left: 3.5px solid transparent;
  border-right: 3.5px solid transparent;
  border-bottom: 3.5px solid #8792a2;
  content: "";
  position: absolute;
  right: 4px;
  bottom: 1px;
}
.css-9gakcf-option {
  color: #3c4fe0 !important;
  position: relative;
}
.css-9gakcf-option::before {
  position: absolute;
  left: 6px;
  top: 11px;
  height: 7px;
  width: 2px;
  background-color: #3c4fe0;
  content: "";
  display: block !important;
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.css-9gakcf-option::after {
  position: absolute;
  left: 7px;
  bottom: 15px;
  height: 2px;
  width: 12px;
  background-color: #3c4fe0;
  content: "";
  display: block !important;
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.infoSocial > div {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.infoSocial + div {
  display: inline;
}
.readmore {
  padding-left: 30px !important;
  font-size: 11px !important;
  text-decoration: underline !important;
  margin-bottom: 20px !important;
}
.infoSocial > div svg,
.infoSocial > div span,
.infoSocial > div a {
  display: inline-block;
}
.infoSocial > div svg {
  width: 18px;
  height: 18px;
  vertical-align: top;
  margin-top: 2px;
}
.infoSocial > div span,
.infoSocial > div a {
  width: calc(100% - 34px);
  margin-left: 13px;
  font-size: 16px;
  color: #1a1f36;
  text-decoration: none;
  margin-bottom: 0px !important;
  padding-left: 0px;
  margin-left: 0px;
}
.scoreCards span[class*="caption"] {
  margin-top: -8px;
  margin-bottom: 16px;
}
.scoreCards span[class*="caption"] img {
  margin-left: 5px;
  zoom: 0.8;
  opacity: 0.5;
}
.scoreCards div {
  padding-right: 0px !important;
}
.ReportsOverview {
  margin-top: 16px !important;
  margin-bottom: 5px !important;
}
.ReportsOverview h5 {
  line-height: 20px;
  font-size: 28px;
  font-weight: bold;
  color: #1a1f36;
  margin-top: 12px;
}
.ReportsOverview div[role="group"] button {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #e3e8ee !important;
  border-radius: 6px;
  font-size: 14px;
  color: #1a1f36;
  font-weight: normal;
}
.ReportsOverview div + button {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #e3e8ee !important;
  border-radius: 6px;
  font-size: 14px;
  color: #1a1f36;
  font-weight: normal;
}
.ReportsOverview div[role="group"] button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ReportsOverview div[role="group"] button:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.adsTab {
  /* margin-top:16px !important; */
  margin-bottom: 5px !important;
}
.scoreCards {
  margin-bottom: 16px !important;
}
.adsTab h5 {
  line-height: 20px;
  font-size: 28px;
  font-weight: bold;
  color: #1a1f36;
  /* margin-top:12px; */
}
.adsTab div[role="group"] button {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #e3e8ee !important;
  border-radius: 6px;
  font-size: 14px;
  color: #1a1f36;
  font-weight: normal;
}
.adsTab div + button {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #e3e8ee;
  border-radius: 6px;
  font-size: 14px;
  /* color: #1A1F36; */
  font-weight: normal;
}
.adsTab div[role="group"] button:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.adsTab div[role="group"] button:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.visitBtn {
  height: 40px;
  /* width: 144px; */
  float: right;
}
.visitBtn:last-child {
  margin-right: 10px !important;
}
.visitBtn:first-child {
  /* margin-right: -50px!important; */
}
.visitBtn {
  margin-right: 10px !important;
}
h6[role="chartCount"] + div {
  margin-top: 15px;
  border-bottom: 1px solid #a8a9b8;
}
h6[role="chartCount"] + div svg g g:first-child {
  display: none;
}
h6[role="chartCount"] + div + span {
  font-size: 12px;
  padding-top: 8px;
  float: left;
}
h6[role="chartCount"] + div + span + span {
  font-size: 12px;
  padding-top: 8px;
  float: right;
}
.SocialTab button[class*="outlined"] {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-color: #e3e8ee !important;
  border: 1px solid #dedfe2;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}
.SocialTab button[class*="outlined"]:first-child {
  width: calc(100% - 185px);
  display: inline-block;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.SocialTab button[class*="outlined"]:first-child > span {
  display: inline-block;
  text-align: left;
}
.SocialTab button[class*="outlined"]:first-child > span span {
  top: 3px;
  float: right;
  position: relative;
}
.SocialTab div[class*="Paper-outlined"] {
  border: 1px solid #dedfe2;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}
.SocialTab div[class*="Paper-outlined"] h6 {
  font-size: 14px;
  font-weight: 500;
  color: #697386;
  line-height: 14px;
  margin-bottom: 15px;
}
.SocialTab div[class*="Paper-outlined"] h4 {
  font-size: 32px;
  font-weight: bold;
  color: #1a1f36;
  line-height: 26px;
}
.SocialTab div[class*="Paper-elevation1"] {
  border: 2px solid #3c4fe0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.3),
    0 6px 12px -2px rgba(50, 50, 93, 0.25);
}
.SocialTab div[class*="Paper-elevation1"] h6 {
  font-size: 14px;
  font-weight: 500;
  color: #697386;
  line-height: 14px;
  margin-bottom: 15px;
}
.SocialTab div[class*="Paper-elevation1"] h4 {
  font-size: 32px;
  font-weight: bold;
  color: #3c4fe0;
  line-height: 26px;
}
.SocialTab svg[role="img"] g g:first-child g {
  display: none;
}
.SocialTab svg[role="img"] {
  overflow: visible;
}
.SocialTab svg[role="img"] g g:first-child text {
  display: none;
}
.socialChartDate span:first-child {
  display: inline-block;
  text-align: left;
  font-size: 14px;
  color: #6e788a;
  margin-left: 22px;
}
.socialChartDate span:last-child {
  float: right;
  font-size: 14px;
  color: #6e788a;
}
.listKey {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  margin-left: 3px;
  font-size: 13px;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 3px 6px;
  margin-bottom: 3px;
  background: #fefefe;
  box-shadow: 1px 1px 1px 0px #f4f4f4;
}
/* .listKey::after
{
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #1A1F36;
  left: -10px;
  top: 6px;
  content: "";
} */
.StoreChart svg {
  height: 28px !important;
}
.StoreChart svg rect + g {
  height: 28px;
}
.StoreChart svg rect + g g g rect {
  height: 28px;
}
.StoreChart svg rect + g g g rect + text {
  transform: translate(0px, 4px);
  opacity: 0;
}
.StoreChart svg rect + g g g {
  transform: translate(0px, 0px);
}
.StoreChart svg g > g:nth-child(2),
.StoreChart svg g > g:nth-child(3) {
  display: none;
}
.StoreHeading {
  border-bottom: 1px solid #e3e8ee;
  padding-bottom: 15px;
}
.StoreHeader {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
}
.StoreHeader + div svg {
  overflow: visible;
  width: 100%;
}
.StoreHeader + div {
  margin-left: 55px;
  width: calc(100% - 45px);
  display: inline-block;
  border-bottom: 1px solid #8792a2;
}
.StoreDataList {
  margin-top: 0px;
  padding-top: 0px;
  border-left: 1px solid #e3e8ee;
  margin-bottom: 0px;
}
.StoreDataList li {
  font-size: 14px;
  color: #697386;
  display: inline-block;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid #e3e8ee;
  width: calc(100% + 20px);
}
.StoreDataList li:last-child {
  border-bottom: none;
}
.StoreDataList li div span {
  display: inline-block;
  width: 100%;
  font-size: 20px;
  color: #1a1f36;
  font-weight: 600;
}
.ProductChartHead {
  /* border-top: 1px solid #E3E8EE; */
  /* padding-top: 10px; */
  /* margin-top: 30px !important; */
}
.KeywordChartHead {
  padding-top: 10px;
  border-top: 1px solid #e3e8ee;
}
.SerAdsHead {
  padding-top: 10px;
  margin-top: 15px !important;
  border-top: 1px solid #e3e8ee;
}
.SerAds {
  flex-grow: 0;
  max-width: calc(25% - 1.5px);
  flex-basis: calc(25% - 1.5px);
  display: inline-block;
  vertical-align: top;
  padding-right: 0px;
  padding-bottom: 0px;
  border: 1px solid #e3e8ee;
  border-right: none;
  margin-bottom: 0px;
  border-collapse: collapse;
}
.SerAds:nth-child(4n),
.SerAds:last-child {
  border-right: 1px solid #e3e8ee !important;
}
.SerAds h4 a {
  font-size: 14px;
  width: calc(100% - 20px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #3c4fe0 !important;
  display: inline-block;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}
.SerAds h4 {
  position: relative;
}
.SerAds h4 span {
  position: absolute;
  left: 0px;
  top: 3px;
}
.SerAds h4 span svg {
  width: 20px;
}
.SerAds h4 + a {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0cab02 !important;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
}
.SerAds p {
  font-size: 12px;
  min-height: 90px;
  padding-left: 10px;
  padding-right: 10px;
}
.SerAds button {
  width: 100%;
  display: inline-block;
  text-align: left;
  background: #f1f1f1;
  padding: 0px;
  border-radius: 0px;
  border-radius: 0px !important;
  margin-top: 0px !important;
  height: auto;
  box-shadow: none !important;
}
.SerAds button span {
  padding-left: 10px;
}
.SerAds button span span {
  float: right;
  margin-right: 15px;
  margin-top: 5px;
  line-height: 1;
}
.SerAds .keywordTab {
  /* border-left:1px solid #E3E8EE; */
  border-right: 1px solid #e3e8ee;
  border-top: 1px solid #e3e8ee;
  background: #fff;
  margin-bottom: -1px;
}
.SerAds .keywordTab h5 {
  font-size: 17px;
  padding-left: 10px;
  margin-top: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
.SerAds .keywordTab table th {
  background: #f0f0f0;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.SerAds .keywordTab table th:first-child {
  width: 50%;
}
.SerAds:first-child .keywordTab {
  width: calc(400% + 6px);
}
/* .SerAds:;nth-child(3) .keywordTab
{
  width:calc(400% + 100px);
  margin-left: calc(-200% - 50px);
} */
.SerAds:nth-child(4n-2) .keywordTab {
  width: calc(400% + 3px);
  margin-left: calc(-100% - 2px);
  border-left: 1px solid #e3e8ee;
}
.SerAds:nth-child(4n-3) .keywordTab {
  width: calc(400% + 3px);
  margin-left: 0px;
}
.SerAds:nth-child(4n + 3) .keywordTab {
  width: calc(400% + 3px);
  margin-left: calc(-200% - 3px);
  border-left: 1px solid #e3e8ee;
}
.SerAds:nth-child(4n) .keywordTab {
  width: calc(400% + 3px);
  margin-left: calc(-300% - 4px);
  border-left: 1px solid #e3e8ee;
}
/* .SerAds:nth-child(3n - 1) .keywordTab
{
  width:calc(300% + 110px);
  margin-left: calc(-100% - 35px);
}
.SerAds:nth-child(3n + 1) .keywordTab
{
  width:calc(300% + 110px);
  margin-left: 0px;
} */
.BarchartTable {
  position: relative;
  padding-left: 16px;
}
.BarchartTable > div {
  border: 1px solid #e3e8ee;
  border-radius: 4px;
  padding-bottom: 20px;
  height: 85% !important;
}
.BarchartTable > div svg g text {
  color: #6e788a;
  font-family: Inter;
  font-size: 16px !important;
  font-weight: 500;
}
.BarchartTable
  > div
  svg
  g
  text[dominant-baseline="text-before-edge"]:last-child {
  display: none;
}
.BarchartTable
  > div
  svg
  g
  g:last-child
  text[dominant-baseline="text-before-edge"],
.BarchartTable
  > div
  svg
  g
  g:first-child
  text[dominant-baseline="text-before-edge"] {
  display: block !important;
}
.BarchartTable > div svg {
  /* width:500px!important; */
}
.BarchartTable > div svg g > line[x2="-5"] {
  display: none;
}
.BarchartTable > div svg > g {
  transform: translate(150px, 16px) !important;
}
.BarchartTable > div svg g > rect {
  height: 28px !important;
}
.BarLines {
  position: absolute;
  left: 155px;
  top: 57px;
  width: calc(100% - 155px);
  height: 77%;
  z-index: -1;
  border: none !important;
}
.BarLines div {
  width: 10%;
  margin-left: 10%;
  position: relative;
  height: 100%;
  border-left: 1px solid #e3e8ee;
  border-right: 1px solid #e3e8ee;
  display: inline-block;
}
.BarLines div:nth-child(2) {
  width: 1px !important;
  border-left: 1px solid #e3e8ee;
  border-right: none;
}
.BarLines span:first-child {
  left: 14px;
}
.BarLines span {
  position: relative;
  bottom: -35px;
  font-size: 12px;
  color: #6e788a;
}
.BarchartTable th {
  border: none;
}
.BarchartTable tbody td {
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  color: #6e788a;
  text-align: right;
}
.BarchartTable tbody td:first-child {
  border-right: 1px solid #a8a9b8;
}
.BarchartTable tbody tr:last-child td {
  height: 45px;
  border: none;
}
.BarchartTable tbody tr:last-child td:last-child {
  border-top: 1px solid #a8a9b8;
  position: relative;
}
.BarchartTable tbody tr:last-child td:last-child::after {
  position: absolute;
  right: 0px;
  top: -1px;
  border-top: 1px solid #fff;
  content: "";
  width: 10px;
}
.ChartTable thead th {
  padding-bottom: 2px;
  padding-top: 2px;
  font-size: 11px;
}
.ChartTable thead th:last-child {
  text-align: right;
}
.ChartTable thead th:last-child svg {
  display: none;
}
.ChartTable tbody td:last-child {
  text-align: right;
}
.ChartTable tbody td {
  font-size: 16px;
  font-weight: 600;
  color: #1a1f36;
  padding-bottom: 8px;
  padding-top: 8px;
  border: none;
}
.KeywordBtns {
  margin-bottom: 10px;
}
.KeywordBtns button {
  margin-bottom: 0px !important;
  margin-right: 12px !important;
}
.KeywordBtns button[class*="Primary"] {
  margin-top: 12px;
  background: #3c4fe0;
}
.StoreDetails {
  border-radius: 4px;
  background-color: #f8fafc;
  margin-top: 40px !important;
  padding-top: 0px;
  margin-bottom: 50px !important;
}
.StoreDetails li {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 3px;
  padding-bottom: 0px;
  font-size: 16px;
  color: #6e788a;
  margin-bottom: 12px;
}
.StoreDetails li:nth-child(3) {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 8px;
}
.StoreDetails li:nth-child(3)::after {
  position: absolute;
  left: 16px;
  width: calc(100% - 32px);
  bottom: 0px;
  content: "";
  border-bottom: 1px solid #dedfe2;
}
.StoreDetails li span {
  display: inline-block;
  width: 45%;
}
.StoreDetails li div {
  width: 50%;
  display: inline-block;
  text-align: right;
}
.StoreDetails li div span {
  display: inline-block;
  color: #1a1f36;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: right;
}
.storeChartY {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #6e788a;
  font-size: 12px;
  padding-top: 5px;
}
.storeChartY span {
  transition: 0.5s all;
}
.storeChartY span:hover {
  font-size: 14px;
  font-weight: 500;
  color: #3c4fe0;
}
.storeChartY span:first-child {
  float: left;
}
.storeChartY span:last-child {
  float: right;
}
.searchTagBtn {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.analyzeBtn {
  position: absolute !important;
  bottom: 5px;
  right: 15px;
  background-color: #3c4fe0 !important;
  color: #fff !important;
  width: 125px;
  height: 30px;
  border-radius: 0px !important;
  padding-top: 3px !important;
  display: none !important;
}
.productBox:hover .analyzeBtn {
  display: block !important;
}
.proResult h6[class*="subtitle2"] {
  height: 28px;
}
.durationFilter {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;
}
.durationFilter > div {
  width: 200px;
}
.durationFilter > div > span + div {
  border: 1px solid #dedfe2;
  border-radius: 15px !important;
  min-height: 30px;
  max-height: 30px;
  font-size: 13px;
  box-shadow: 0px 2px 3px #ccc !important;
  background: #fff !important;
}
.durationFilter > div > span + div + div > div > div {
  padding-left: 35px;
}
.durationFilter > div > div + div {
  border: 1px solid #dedfe2;
  border-radius: 3px !important;
  font-size: 13px;
  box-shadow: 0px !important;
  background: #fff !important;
}
.durationFilter > div > span + div > div div {
  top: 10px;
  left: 15px;
}
.nxtPreBtns {
  display: inline-block;
  text-align: right;
  width: 100%;
  padding-right: 8px;
}
.nxtPreBtns button {
  display: inline-block;
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.adsImg {
  width: calc(100% + 22px) !important;
  display: inline-block;
  height: 495px !important;
  overflow: hidden;
  margin-left: -11px;
  margin-right: 0px !important;
  margin-bottom: -10px;
  background: #f7f7f7;
}
.adsImg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.adsheader {
  display: inline-block;
  width: calc(100% - 30px);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
}
.adsheader > span {
  display: inline-block;
  width: 145px;
  vertical-align: top;
  font-size: 12px;
}
.adsheader span.Active svg {
  fill: #01b201;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  /* top: 2px; */
  width: 20px;
}
.adsheader span.Inactive svg {
  fill: #ccc;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  /* top: 2px; */
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.SocialTab div[class*="Paper-elevation1"] h4 {
  font-size: 32px;
  font-weight: bold;
  color: #3c4fe0;
  line-height: 26px;
}
.adsheader div {
  margin-left: 3px;
}
.adsheader div i {
  color: #666;
  margin-left: 3px;
}
.adsheader div.sdate,
.adsheader div.ldate {
  display: inline-block;
  width: 50%;
  white-space: nowrap;
  float: none;
}
.adsheader div.ldate {
  display: none !important;
}
.adsheader div.ldate + p {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 13px;
}
.adsheader + div {
  border-radius: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  width: calc(100% - 20px);
  overflow: hidden;
  height: 620px;
  padding-bottom: 0px !important;
}
.adsheader + div > div {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.adsheader + div > div[class*="circular"] {
  width: 30px !important;
  height: 30px !important;
}
.adsheader + div a {
  color: #000;
  text-decoration: none;
  width: calc(100% - 50px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.adsheader + div p {
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 10px;
  height: 80px;
}
.adsheader + div + a {
  /* background-color: #e4e6eb; */
  border-radius: 15px !important;
  /* height: 30px; */
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 1px solid #05169e !important;
  text-transform: none !important;
  box-shadow: 0px 3px 3px #ccc !important;
  color: #fff;
  background: #3c4fe0;
  font-size: 11px;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.adsheader + div + a:hover {
  background-color: #05169e;
}
.adItems > div {
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 1px 10px 5px rgb(0 0 0 / 8%);
}
.ANfacebook {
  background: url("../images/ANFacebook.svg") 0px 3px no-repeat;
  display: inline-block;
  width: 20px;
  height: 18px;
  vertical-align: bottom;
  top: -2px;
  position: relative;
}
.customSort {
  display: inline-block !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dedfe2 !important;
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  padding-left: 16px !important;
}
.customSort div {
  border: none;
}
.customSort div[aria-haspopup="listbox"] {
  padding-top: 0px;
  padding-bottom: 0px;
}
.customSort label {
  transform: none !important;
  position: relative;
  display: inline-block;
}
.customSort fieldset {
  opacity: 0;
}
.adsFilterHead {
  margin-top: 24px !important;
  margin-bottom: 16px !important;
}
.adsFilterHead h5 {
  font-size: 24px;
  font-weight: 600;
  color: #1a1f36;
}
.adsFilterHead button[class*="outlined"] {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  height: 40px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dedfe2;
  border-radius: 6px;
}
div.modalStore div[class*="Container"] {
  max-width: 1000px;
  width: 100%;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fff;
  padding: 30px;
  /* height:400px; */
  margin-top: 50px;
  margin-bottom: 50px;
  overflow-x: auto;
}
div.modalStore div[class*="Container"] table {
  border: 1px solid #eee;
}
div.modalStore div[class*="Container"] table th {
  background: #eee;
  font-weight: bold;
}
div.modalStore div[data-test="sentinelStart"] + div {
  position: relative;
  display: inline-block;
}
div.modalStore div[data-test="sentinelStart"] + div button {
  position: absolute;
  right: 10px;
  top: 60px;
  padding: 0px !important;
  min-width: 30px !important;
}
div.modalStore {
  width: 100%;
  overflow-y: auto;
  text-align: center;
}
.starRating {
  margin: 0px !important;
}
.starRate p {
  margin: 0px !important;
}
.starRate p > span {
  width: auto !important;
}
.starRate p > span + span {
  font-size: 11px;
  padding-left: 5px;
  margin: 0px !important;
}
.starRating[aria-label="1.5 Stars"] span[class*="iconFilled"] svg,
.starRating[aria-label="1 Star"] span[class*="iconFilled"] svg,
.starRating[aria-label="0.5 Stars"] span[class*="iconFilled"] svg {
  background: #fb3821 !important;
}
.starRating[aria-label="2.5 Stars"] span[class*="iconFilled"] svg,
.starRating[aria-label="2 Stars"] span[class*="iconFilled"] svg {
  background: #ff841f !important;
}
.starRating[aria-label="3.5 Stars"] span[class*="iconFilled"] svg,
.starRating[aria-label="3 Stars"] span[class*="iconFilled"] svg {
  background: #ffcc00 !important;
}
.starRating[aria-label="4 Stars"] span[class*="iconFilled"] svg {
  background: #72cf0e !important;
}
.starRating[aria-label="4.5 Stars"] span[class*="iconFilled"] svg,
.starRating[aria-label="5 Stars"] span[class*="iconFilled"] svg {
  background: #01b57b !important;
}
.starRating > span {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 0px !important;
  height: 25px;
}
.starRating span svg {
  margin-right: 2px !important;
  margin-right: 2px;
}
.starRating span[class*="iconFilled"] svg path {
  fill: #fff;
}
.starRate {
  font-size: 12px;
  padding-left: 30px;
}
.starRating span[class*="iconEmpty"] svg path {
  fill: #fff;
}
.starRating span[class*="iconEmpty"] svg,
.starRating span[class*="iconFilled"] svg {
  background: #dcdce4;
}
.avgRunningTime p {
  color: #697386;
  font-size: 12px;
  padding-left: 23px;
}
.avgRunningTime p span {
  font-weight: 600;
  color: #000;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  padding-top: 5px;
}
.avgRunningTime p.Active,
.avgRunningTime p.Inactive {
  font-size: 12px;
}
.avgRunningTime h6.Active,
.avgRunningTime h6.Inactive {
  font-size: 15px;
}
.avgRunningTime p.Active svg {
  fill: #01b201;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  width: 20px;
}
.avgRunningTime h6.Active svg {
  fill: #01b201;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  width: 20px;
  margin-right: 3px;
}
.avgRunningTime p.Inactive svg {
  fill: #ccc;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.avgRunningTime h6.Inactive svg {
  fill: #ccc;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.badgeBtn {
  display: inline-block;
  background-color: rgb(203, 244, 201);
  color: rgb(11, 130, 93);
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  height: 26px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  line-height: 1.7;
  vertical-align: middle;
  margin-right: 25px;
}
.filterChoicePopup div[data-test="sentinelStart"] + div {
  background: #fff;
  padding: 15px;
  max-width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}
.filterChoicePopup h5 {
  margin-top: 0px;
  font-size: 13px;
  padding-bottom: 5px;
  font-weight: bold;
  margin-bottom: 10px;
}
.filterChoicePopup div[data-test="sentinelStart"] + div div {
  /* display:inline-block; */
  /* width:50% */
  min-height: auto;
}
.filterChoicePopup div[data-test="sentinelStart"] + div div h6 {
  display: inline-block;
  font-size: 12px;
  padding-left: 5px;
}
.filterChoicePopup div[role="tabpanel"] > div div {
  margin-bottom: 5px;
}
.filterChoicePopup div[role="tabpanel"] {
  /* margin-bottom: 15px; */
}
.filterChoicePopup button[class*="Primary"] {
  margin-top: 25px;
}
.popSocial {
  max-width: 50%;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.popSocial div {
  margin-bottom: 5px;
}
.popSocial span,
.filterChoicePopup span {
  margin: 0px;
  padding: 0px;
}
.popSocial span path,
.filterChoicePopup span path {
  fill: #ddd;
}
.popSocial span[class*="checked"] path,
.filterChoicePopup span[class*="checked"] path {
  fill: #3f51b5;
}
.filterChoicePopup div[role="tablist"] button {
  margin: 0px;
  width: auto;
  min-width: auto;
  min-height: auto;
  color: #3f51b5;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
}
.filterChoicePopup button[class*="selected"] {
  color: #fff !important;
  background: #3f51b5;
}
.filterChoicePopup span[class*="PrivateTabIndicator"] {
  display: none;
}
.addFilter {
  width: calc(100% - 60px);
  display: inline-block;
}
.addFilter .inputs > div {
  max-width: 55%;
  width: 100%;
  display: inline-block;
}
.addFilter .withSliders {
  max-width: 300px;
  display: inline-block;
  width: 100%;
}
.addFilter .withSliders + div {
  margin-left: 130px;
  margin-top: 22px;
}
.addFilter .inputs input.visitBtn:first-child {
  width: 156px;
}
.addFilter span {
  font-size: 12px;
}
.addFilters {
  margin-bottom: 20px;
}
.countryRow {
  position: relative;
  white-space: nowrap;
}
.addFilter + .closeBtn {
  position: relative;
  right: -15px;
}
.searchBrandPaper .addFilters:nth-child(1) > div:first-child {
  max-width: 16.666667%;
  flex-basis: 16.666667%;
}
.searchBrandPaper .addFilters:nth-child(2) > div:first-child {
  max-width: 16.666667%;
  flex-basis: 16.666667%;
}
.searchBrandPaper .addFilters:nth-child(3) > div:first-child {
  max-width: 16.666667%;
  flex-basis: 16.666667%;
}
.addFilter fieldset {
  opacity: 0;
}
.addFilter input {
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
  height: 40px;
  border: 1px solid #dedfe2 !important;
  border-radius: 6px;
  padding-left: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 10px;
  background: #fff;
}
.addFilter label[class*="shrink"] {
  /* opacity:0; */
  font-size: 14px;
  background: #fff;
}
.addFilter > div {
  margin-left: 30px;
}
.addFilter > div > span + span {
  margin-left: 25px;
}
.addFilter > div div > span + span {
  margin-left: 40px;
  position: relative;
  padding-top: 17px;

  /* flex-grow: 1; */
}
.addFilter > div div > span + span::before {
  background-color: #eceff3;
  padding-top: 7px;
  padding-left: 40px;
  padding-right: 58px;
  padding-bottom: 7px;
  border-radius: 4px;
  height: 26px;
  content: "";
  left: -40px;
  top: 0px;
  position: absolute;
  width: 100%;
}
.addFilter > div div {
  width: 90%;
}
/* .productTabResult .BrandPagination
{

  position: relative;
  right: 0px;
  bottom: -15px;
  background-color: #F7FAFC;
  height: 56px;
  border-top: 1px solid #E3E8EE;
}
.productTabResult .BrandPagination nav + label + div + label
{
  position: absolute;
  left: 15px;
  font-size: 14px;
  bottom: 20px;
}
.productTabResult .BrandPagination nav + label + div + label + div
{
  position: absolute;
  left: 150px;
  bottom: 15px;
}
.productTabResult .BrandPagination nav
{
  position: absolute;
  right: 160px;
  top: 13px;
}
.productTabResult .BrandPagination nav
{

}
.productTabResult .BrandPagination nav + label, .productTabResult .BrandPagination nav + label + div
{

  display: inline-flex;
  font-size: 14px;
  margin-top: 15px;
  margin-right: 15px;
} */
.productBox.proResult {
  max-width: calc(25% - 16px) !important;
  flex-basis: calc(25% - 16px) !important;
}
.productBox {
  max-width: calc(25% - 16px) !important;
  flex-basis: calc(25% - 16px) !important;
}
.react-multi-carousel-track .productBox {
  max-width: calc(100% - 16px) !important;
  flex-basis: calc(100% - 16px) !important;
}
.priceTable {
  margin: 20px;
  margin-left: 0px;
  padding: 20px;
}
.priceImg {
  width: 100%;
  display: inline-block;
  height: 200px;
  background: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
.priceImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.priceTable h4 {
  color: #dd0303;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.priceTable h6 {
  color: #000000;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
}
.NotFound {
  text-align: center;
  padding-top: 100px;
}
.NotFound h6 {
  font-size: 21px;
  padding-top: 0px;
  margin-top: 10px;
  font-weight: normal;
  text-transform: uppercase;
}
.error {
  text-align: center;
  padding: 80px;
}
.error div {
  padding: 20px;
  background: #f1f1f1;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
}
.error div span {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 16;
  vertical-align: middle;
  /* margin-right: 50px; */
}
.error div span.paySxss {
  background: #008cfe;
}
.error div a {
  border-radius: 15px !important;
  height: 25px;
  font-size: 13px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 1px solid #05169e !important;
  text-transform: none !important;
  box-shadow: 0px 3px 3px #ccc !important;
  background: #3c4fe0 !important;
  color: #fff;
  min-width: 64px;
  box-sizing: border-box;
  display: inline-block;
  line-height: 23px;
  text-decoration: none;
}
.error div h2 {
  /* display: inline-block; */
}
.error div span img {
  object-fit: contain;
  width: 80%;
  object-position: center center;
}
.v1-loginBg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100vh;
}
.v1-loginBox {
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 17%;
}
.v1-SignupBox {
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
}
.v1-loginBox img {
  margin-bottom: 24px;
}
.v1-SignupBox img {
  margin-bottom: 35px;
}
.v1-SignupBox form div span {
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-top: 7px;
  vertical-align: middle;
}
.v1-login form div span {
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-top: 7px;
  vertical-align: middle;
}
.v1-Signup form div input {
  width: calc(100% - 40px);
  height: 40px;
  border: none;
  padding: 0px;
  outline: none;
  background: #eceff1;
  display: inline-block;
  border-radius: 4px;
}
.v1-login form div input {
  width: calc(100% - 40px);
  height: 40px;
  border: none;
  padding: 0px;
  outline: none;
  background: #eceff1;
  display: inline-block;
  border-radius: 4px;
}
.v1-login form div span svg {
  fill: #607d8b;
}
.v1-Signup form div span svg {
  fill: #607d8b;
}
.v1-login a {
  float: right;
  font-size: 14px;
  margin-top: 16px;
}
.v1-login button {
  background: #00f;
  color: #fff;
  text-align: left;
  border-radius: 4px !important;
  padding-right: 20px;
  padding-left: 20px;
}
.v1-Signup button {
  background: #00f;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: -15px;
}
.v1-Signup button span {
  text-align: left;
  justify-content: space-between;
}
.v1-Signup button span svg {
  fill: #00f;
  background: #fff;
  border-radius: 50%;
}
.v1-login button:hover,
.v1-Signup button:hover {
  background: #000093;
}
.v1-login button span {
  text-align: left;
  justify-content: space-between;
}
.createAcc {
  padding: 24px;
  text-align: right;
}
.createAcc a {
  font-size: 14px;
  font-weight: bold;
  color: #00f;
  margin-left: 25px;
}
.createAcc a svg + span {
  vertical-align: top;
  margin-left: 5px;
}
.createAcc a span {
  display: inline-block;
  vertical-align: text-top;
  margin-left: 10px;
}
input:-internal-autofill-selected {
  background: #eceff1 !important;
}
.v1-Signup form div {
  display: inline-block;
  width: 100%;
  background: #eceff1;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 35px;
  position: relative;
  /* overflow: hidden; */
}
.v1-Signup form div p {
  position: absolute;
  bottom: -25px;
  right: 0px;
  font-size: 11px;
}
.v1-login form div {
  display: inline-block;
  width: 100%;
  background: #eceff1;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 8px;
  overflow: hidden;
  position: relative;
}
.v1-login form div input + span {
  position: absolute;
  right: 0px;
}
.v1-Signup form div input + span {
  position: absolute;
  right: 0px;
}
.v1-Signup form div label {
  position: absolute;
  top: -20px;
  font-size: 14px;
  color: #455a64;
}
.v1-Signup form {
  width: 100%;
  display: inline-block;
}
.v1-login form {
  width: 100%;
  display: inline-block;
}
.v1-login {
  width: 100%;
  display: inline-block;
}
.auth__left {
  padding: 96px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(40% - 192px);
  flex: 0 0 calc(40% - 192px);
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  overflow: auto;
  position: relative;
  /* background-image: url("../images/auth.jpg"); */
  background-size: cover;
  background-position: 50%;
  padding-left: 125px;
  padding-right: 50px;
}
.auth__info {
  position: relative;
  z-index: 9;
}
.auth__info ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 0.9;
  font-size: 36px;
}
.auth__right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(60% - 48px);
  flex: 0 0 calc(60% - 48px);
  overflow: auto;
  padding: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.auth__left:after {
  background-size: cover;
  position: absolute;
  background: rgb(1, 1, 209);
  /* background-color: #a0f; */
  /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ba0de1'/%3E%3Cstop offset='1' stop-color='%23ba0de1' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ff0074'/%3E%3Cstop offset='1' stop-color='%23ff0074' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23e10d4e'/%3E%3Cstop offset='1' stop-color='%23e10d4e' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23a0f'/%3E%3Cstop offset='1' stop-color='%23a0f' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23be19c4'/%3E%3Cstop offset='1' stop-color='%23be19c4' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ff6d00'/%3E%3Cstop offset='1' stop-color='%23ff6d00' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Cpath fill='url(%23a)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23b)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23c)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23d)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23e)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23f)' d='M0 0h1200v800H0z'/%3E%3C/svg%3E"); */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  opacity: 0.7;
  background-blend-mode: lighten;
}
.stripe {
  width: 500px;
  margin-top: 50px;
  padding-bottom: 50px;
}
.stripe p {
  font-size: 10px;
  margin-top: 0px;
  margin-bottom: 15px;
}
#payment-message {
  margin-bottom: 15px;
}
.stripe button#submit {
  border-radius: 15px !important;
  height: 30px;
  font-size: 13px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 1px solid #05169e !important;
  text-transform: none !important;
  box-shadow: 0px 3px 3px #ccc !important;
  background: #3c4fe0 !important;
  color: #fff;
  margin-top: 20px;
}
.stripe button#submit:hover {
  background: #05169e !important;
}
.react-multi-carousel-list {
  padding-top: 10px;
  padding-bottom: 10px;
}
.react-multi-carousel-list .adItems {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.reportTable {
  margin-left: 10px;
  margin-bottom: 25px;
}
.reportTable th,
.reportTable td {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}
.reportTable th {
  font-weight: bold;
}
.ProductChartHead + p {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 0px;
}
.ProductChartHead + ul {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 0px;
}
.ProductChartHead + ul + p {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 0px;
  width: 100%;
  display: inline-block;
}
.ProductChartHead + ul + p + p {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 0px;
  width: 100%;
  display: inline-block;
}
.ActSettings {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  position: relative;
}
.ActSettings h3 {
  width: 100%;
  display: inline-block;
  position: relative;
  font-size: 25px;
  margin-bottom: 0px;
}
.ActSettings .general div[class*="container"] {
  grid-gap: 20px;
}
.ActSettings h3::before {
  position: absolute;
  left: 0px;
  top: -17px;
  /* content: "OVERVIEW"; */
  font-size: 11px;
  font-weight: normal;
  color: #3c4257;
  letter-spacing: 1.3px;
}
.ActSettings .general div[class*="FormControl"] {
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}
.ActSettings .security {
  position: relative;
}
.ActSettings .security div[class*="FormControl"] {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.ActSettings .security a + div {
  grid-gap: 20px;
}
.ActSettings .general div[class*="FormControl"] input {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background: #f1f1f1;
}
.ActSettings h3 + button {
  position: absolute;
  right: 45px;
  top: 63px;
}
.ActSettings .security div[class*="FormControl"] input {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background: #ffffff;
}
.ActSettings .general div[class*="FormControl"] label,
.ActSettings .security div[class*="FormControl"] label {
  font-size: 12px;
  margin-bottom: 5px;
}
.ActSettings .security div > span {
  position: absolute;
  top: 28px;
  right: 10px;
}
.ActSettings .security a {
  position: absolute;
  right: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #1a1f36;
  font-size: 13px;
  top: 0px;
}
.ActSettings .security div[class*="Paper"] {
  margin-top: 10px;
  padding: 20px;
}
.ActSettings .security div[class*="Paper"] h4 {
  font-size: 18px;
}
.ActSettings .security div[class*="Paper"] p,
.ActSettings .security div[class*="Paper"] ul li,
.ActSettings .Billing div[class*="Paper"] p,
.ActSettings .Billing div[class*="Paper"] ul li {
  font-size: 13px;
}
.ActSettings .general div div[class*="6"] {
  max-width: 49%;
  flex-basis: 49%;
}
.ActSettings .security div div[class*="6"] {
  flex-basis: 49%;
}
.ActSettings .general div[class*="FormControl"] span {
  position: absolute;
  right: 10px;
  top: 28px;
}
.ActSettings .Billing h5 {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
}
.ActSettings .btn {
  background: #775ddc;
  color: #fff;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding: 6px 15px;
  border: none;
  cursor: pointer;
}
.ActSettings .btn:hover {
  background: #5945a7;
  color: #fff;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.ActSettings .Billing div[class*="Paper"] {
  padding: 5px;
  margin-bottom: 15px;
  height: auto;
  line-height: 30px;
  padding-left: 15px;
}
.ActSettings .Billing div[class*="Paper"] button {
  float: right;
}
.ActSettings .plans table th {
  vertical-align: top;
  text-align: center;
}
.ActSettings .plans table th p {
  color: #a3b2d0;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0px;
}
.ActSettings .plans table th h5 span {
  font-size: 30px;
  font-weight: bold;
}
.ActSettings .plans table th h5 {
  margin-bottom: 0px;
}
.ActSettings .plans table th h5 + p {
  margin-top: 0px;
}
.ActSettings .plans table th button {
  width: 170px;
}
.ActSettings .plans table th button + p {
  margin-top: 10px;
  text-align: left;
  line-height: 10px;
  width: 170px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ActSettings .plans table th button + p svg {
  width: 15px;
  height: 15px;
}
.ActSettings .plans table td {
  font-size: 12px;
  white-space: nowrap;
}
.ActSettings .plans table td h6 {
  font-size: 13px;
  font-weight: bold;
}
.ActSettings .plans table td svg {
  width: 15px;
  height: 15px;
}
.ActSettings .plans .btngrp {
  text-align: center;
  margin-bottom: 15px;
}
.ActSettings .plans .btngrp button:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ActSettings .plans .btngrp button:last-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #8699b7 !important;
}
.billingFooter {
  display: inline-block;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .v1-loginBg {
    width: 100%;
    display: inline-block;
    /* background-image: url("../images/auth.jpg"); */
    background-size: cover;
    background-position: 50%;
    margin-left: 0px;
    height: 120vh;
  }
  .ActSettings .general div div[class*="6"],
  .ActSettings .security div div[class*="6"] {
    max-width: 100%;
    flex-basis: 100%;
  }
  .ActSettings {
    padding: 30px;
  }
  .ActSettings h3 + button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .v1-trending h4 {
    font-size: 21px !important;
  }
  .ActSettings .security a {
    position: relative;
    top: -15px;
  }
  .v1-loginBg::after {
    background-size: cover;
    position: absolute;
    /* background-color: #a0f; */
    background: rgb(1, 1, 209);
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ba0de1'/%3E%3Cstop offset='1' stop-color='%23ba0de1' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ff0074'/%3E%3Cstop offset='1' stop-color='%23ff0074' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23e10d4e'/%3E%3Cstop offset='1' stop-color='%23e10d4e' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23a0f'/%3E%3Cstop offset='1' stop-color='%23a0f' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23be19c4'/%3E%3Cstop offset='1' stop-color='%23be19c4' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ff6d00'/%3E%3Cstop offset='1' stop-color='%23ff6d00' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Cpath fill='url(%23a)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23b)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23c)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23d)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23e)' d='M0 0h1200v800H0z'/%3E%3Cpath fill='url(%23f)' d='M0 0h1200v800H0z'/%3E%3C/svg%3E"); */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    opacity: 0.7;
    background-blend-mode: lighten;
    height: 150%;
  }
  .auth__left {
    flex-basis: calc(100%);
    padding: 30px;
    background: none;
    text-align: center;
  }
  .auth__left::after {
    background: none;
  }
  .auth__right {
    flex-basis: calc(100%);
    position: relative;
    z-index: 99;
    top: -45px;
    overflow: hidden;
  }
  .v1-SignupBox,
  .v1-loginBox {
    padding: 20px;
    background: #fff;
    width: calc(100% - 40px);
  }
  .v1-SignupBox img,
  .v1-loginBox img {
    margin-bottom: 50px;
    width: 100%;
  }
  .createAcc {
    text-align: center;
    padding: 10px;
  }
  .createAcc a svg + span {
    vertical-align: text-bottom;
    margin-left: 5px;
  }
  .auth__info ul {
    font-size: 28px;
    line-height: 35px;
  }
  .v1-loginBox {
    margin-top: 8%;
  }
  .createAcc a {
    color: #fff;
    display: inline-block;
    width: 100%;
    line-height: 30px;
    margin-left: 0px;
  }
  .mobileDropDown {
    display: inline-block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .mobileDropDown > div {
    width: 100%;
    display: inline-block;
    background: #683bb7;
    border: 1px solid #3b4edf;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    color: #fff !important;
  }
  .mobileDropDown > div svg {
    color: #fff !important;
    right: 6px;
  }
  .mobileDropDown div::before,
  .mobileDropDown div::after {
    display: none;
  }
  .mobileTabContent {
    min-height: 300px;
    padding: 15px;
    width: 100%;
    display: inline-block;
  }
  .mobileTabContent .v1-trending .accessPop {
    padding: 15px;
    width: calc(100% - 30px);
  }
}
