@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
.navBar.v1 header > div {
  min-height: 100px;
}
.navBar.v1 .searchBtn {
  position: absolute;
  right: 15px;
  width: 18px;
  height: 18px;
  fill: #a6b2c1;
}
.navBar.v1 .searchBox input {
  margin-top: 5px;
}
.navBar.v1 .searchBox label {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 18px;
  line-height: 45px;
  padding-left: 30px;
  transform: none;
}
.v1-loginBox img,
.v1-SignupBox img {
  width: 300px;
}
.v1-hmenu {
  position: fixed;
  height: 100%;
  z-index: 9;
  overflow: hidden;
  top: 0px;
}
.v1-Signup button + p {
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 30px;
}
header div img + div {
  margin-right: auto;
}
div[role="tooltip"] div[class*="Arrow"] {
  font-size: 14px;
}
div[role="tooltip"]:nth-child(even) div[class*="PlacementTop"] {
  top: 22px;
}
div[role="tooltip"] div[class*="PlacementTop"] {
  color: #6e56f8 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 10px;
  line-height: 12px;
  margin: 0px;
  top: 28px;
  position: relative;
}
.v1-hmenu > div {
  /* width: 100% !important;
  max-width: 300px;
  min-width: 100px; */
  display: inline-block;
  /* position: relative; */
  overflow: visible;
  position: relative;
  border: none;
}
.navBar.v1 div button[aria-label="close drawer"] {
  position: absolute;
  display: none;
  z-index: 99;
  top: 27px;
  left: 15px;
}
.slider.navBar.v1 div button[aria-label="close drawer"] {
  display: block;
}
.v1-trending {
  transition: margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  margin-left: 0px;
  position: relative;
  z-index: 999;
  margin-top: 30px;
}
.v1-trending::before {
  position: absolute;
  z-index: -1;
  width: calc(100% + 60px);
  height: calc(100% + 60px);
  left: -30px;
  top: -30px;
  background: #f2f4fd;
  content: "";
}
.v1-trending::after {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #fff;
  content: "";
  border-radius: 10px;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08) !important;
}
.v1-trending .accessPop {
  position: absolute;
  top: 580px;
  left: 0px;
  width: 100%;
  height: calc(100% - 580px);
  background: rgba(255, 255, 255, 0.8);
  z-index: 99;
  border-radius: 10px;
  text-align: center;
}
.v1-trending .accessPop > div {
  position: relative;
  top: calc(50% - 100px);
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .accessPop > div button.primary {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 6px;
  background: #3b4edf;
  border: 1px solid rgba(0, 40, 100, 0.12);
  height: 42px;
  min-width: 203px;
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  padding-right: 20px;
}
.v1-trending .accessPop > div button.default,
.v1-trending .accessPop > div button.default + a {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  background: #fff;
  border: 1px solid #3b4edf;
  border-radius: 5px;
  height: 42px;
  max-width: 235px;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  color: #3b4edf;
  font-weight: 400;
  cursor: pointer;
  padding-right: 0px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 22px;
  width: 100%;
  text-decoration: none;
  line-height: 42px;
}
.v1-trending .accessPop > div button.primary svg {
  display: inline-block;
  vertical-align: text-bottom;
  position: relative;
}
.v1-trending .accessPop > div button.primary span {
  position: relative;
}
.v1-trending .accessPop > div button.primary span::after {
  position: absolute;
  content: "";
  background: #fd4e5d;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  left: -11px;
  top: -2px;
}
.v1-trending .accessPop > div p {
  margin-top: 25px;
  line-height: 25px;
  font-size: 14px;
}
.v1-trending .accessPop > div p a {
  color: #683bb7;
  font-weight: 700;
}
.v1-trending .bestSeller > div > h6 {
  opacity: 0;
  height: 0px;
  transition: all 0.5s;
  font-size: 18px;
  display: inline-block;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}
.v1-trending .bestSeller > div > .feauredIcons {
  /* opacity: 0;
  height: 0px; */
  text-align: center;
  width: 100%;
  display: inline-block;
}
.v1-trending .bestSeller > div > .feauredIcons button {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: rgba(59, 78, 223, 0.1);
  border: none;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.v1-trending .bestSeller > div > .feauredIcons + button {
  opacity: 1;
  height: 30px;
  transition: all 0.5s;
}
.v1-trending .FeaturedSlider .bestSeller:hover > div > .feauredIcons + button {
  opacity: 1;
  height: 30px;
}
.v1-trending .FeaturedSlider .bestSeller:hover > div > .feauredIcons {
  opacity: 1;
  height: 38px;
}
.v1-trending .FeaturedSlider {
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
  position: relative;
  /* top:-60px; */
}
.v1-trending .FeaturedSlider > h6 {
  padding-left: 10px;
}
.v1-trending .FeaturedSlider .bestSeller > div > h6 {
  opacity: 1;
  height: 25px;
}
.v1-trending .FeaturedSlider .bestSeller > div {
  transition: all 0.5s;
}
.v1-trending .FeaturedSlider .bestSeller > div {
  height: 440px;
  /* margin-top: -20px; */
}
.v1-trending .FeaturedSlider .react-multi-carousel-list {
  align-items: start !important;
}
.v1-trending .FeaturedSlider .react-multi-carousel-list ul {
  /* margin-top: 50px!important; */
}
.v1-trending
  .FeaturedSlider
  .react-multi-carousel-list
  .react-multiple-carousel__arrow {
  top: 200px;
}
.slider.v1-trending {
  transition: margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  margin-left: 300px !important;
  /* min-width: 100%; */
  /* width: calc(100% - 360px); */
}
.Iconlogo {
  display: none;
  width: 50px;
  margin-top: 25px;
  margin-left: 9px;
  position: relative;
}
.slider .Iconlogo-sm {
  display: none;
}
.Iconlogo + div {
  width: 100%;
}
.Iconlogo + div + a {
  white-space: nowrap;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
  margin-top: 40px;
}
.Iconlogo + div + p + span {
  margin-top: 30px;
}
.slider .Iconlogo {
  display: none;
}
.navBar.v1 .NotifyIcon {
  background: rgba(59, 78, 223, 0.05);
  border-radius: 5px;
  width: 50px;
  height: 50px;
}
.navBar.v1 .loginBtn {
  width: 50px;
  height: 50px;
  padding: 0px !important;
}
.navBar.v1 .loginBtn div[class*="avatar"] {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0px;
}
.v1-hmenu ul div div {
  display: none;
  line-height: 75px;
}
.v1-hmenu ul div div span {
  display: inline-block;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
}
.v1-hmenu ul div {
  position: relative;
  height: 75px;
  line-height: 70px !important;
}
.slider .v1-hmenu ul div[role="button"].selected div span {
  color: #fff;
}
.v1-hmenu ul div[role="button"].selected img {
  filter: invert(49%) sepia(19%) saturate(1820%) hue-rotate(196deg)
    brightness(92%) contrast(96%);
}
.v1-hmenu ul div[role="button"]:hover img {
  filter: invert(49%) sepia(19%) saturate(1820%) hue-rotate(196deg)
    brightness(92%) contrast(96%);
}
.v1-hmenu ul > div:hover::before,
.v1-hmenu ul > div.selected::before {
  position: absolute;
  left: -24px;
  top: 0px;
  background: url("../images/v1-icons/selected.svg") no-repeat center left;
  content: "";
  width: 14px;
  height: 75px;
}
.slider .v1-hmenu ul > div:hover::before,
.slider .v1-hmenu ul > div:hover::before,
.slider .v1-hmenu ul > div.selected::before {
  display: none;
}
.slider .v1-hmenu ul > div:hover div path {
  fill: #fff !important;
}
.slider .v1-hmenu ul > div:hover div span {
  color: #fff;
}
.slider .v1-hmenu ul > div:hover img,
.slider .v1-hmenu ul > div.selected img {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(132%)
    contrast(102%);
}
.slider .v1-hmenu ul > div:hover::after,
.slider .v1-hmenu ul > div.selected::after {
  background: #3b4edf;
  content: "";
  left: 0px;
  width: 250px;
  height: 54px;
  top: 15px;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
}
.v1-hmenu ul div:hover,
.v1-hmenu ul div:focus,
.v1-hmenu ul div:active {
  background: transparent !important;
}
.v1-hmenu ul div span {
  display: none;
}
.slider .v1-hmenu ul div div {
  display: block !important;
}
.v1-hmenu ul div div:first-child {
  display: block !important;
}
.ToggleBtn {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 999;
}
.ToggleBtn button {
  transform: rotate(180deg);
}
.navBar.v1 {
  background: #f2f4fd;
  padding: 0px !important;
  height: 100px;
}
.navBar.v1 button[aria-label="open drawer"] {
  margin-top: 32px;
  position: absolute;
  left: 135px;
  z-index: 999;
}
.navBar.v1 button[aria-label="close drawer"] img {
  transform: rotate(180deg);
}
.navBar.v1 header {
  box-shadow: none;
  z-index: 9999;
  position: fixed;
}
.navBar.v1 header::after {
  height: 100px;
  width: 1px;
  content: "";
  position: absolute;
  left: 100px;
  top: 0px;
  background: rgba(59, 78, 223, 0.2);
  z-index: 99999;
  display: block;
}
.slider.navBar.v1 header::after {
  display: none;
}
.navBar.v1 div[class*="h6"] img {
  max-width: 200px;
}
.slider.navBar.v1 div[class*="h6"] + hr {
  background: rgba(59, 78, 223, 0.2);
  border: none;
}
.navBar.v1 div[class*="h6"] + hr {
  background: #fff;
  border: none;
}
.navBar.v1 + div {
  background: #f2f4fd;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 30px;
}
.navBar.v1 .searchBox {
  margin-left: 125px;
  background: rgba(59, 78, 223, 0.05);
  border: none;
  box-shadow: none;
  border-radius: 10px;
  height: 50px;
  z-index: 99999;
  position: relative;
}
div[class*="popper"][role="presentation"] {
  z-index: 9999;
  margin-left: -5px !important;
  border: 1px solid #ddd;
  background: #fff;
  font-family: "Poppins !important";
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px;
}
div[class*="popper"][role="presentation"] > div {
  background: #f5f6fd !important;
  margin-top: -10px;
  padding-top: 10px;
  border-radius: 0px 0px 10px 10px !important;
  overflow: hidden;
  box-shadow: none;
  border: none;
}
div[class*="popper"][role="presentation"] > div ul {
  overflow-x: hidden;
}
div[class*="popper"][role="presentation"] > div ul li,
div[class*="popper"][role="presentation"] > div ul li div {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
div[class*="popper"][role="presentation"] > div ul li div svg,
div[class*="popper"][role="presentation"] > div ul li div span,
div[class*="popper"][role="presentation"] > div ul li div p {
  display: inline-block;
}
div[class*="endAdornment"] {
  display: none;
}
div[class*="popper"][role="presentation"] > div ul li div svg {
  margin-right: 10px;
}
div[class*="popper"][role="presentation"] > div ul li div span {
  width: calc(100% - 50px);
  vertical-align: top;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold;
  color: #3d4465;
}
div[class*="popper"][role="presentation"] > div ul li div p {
  width: calc(100% - 50px);
  margin-left: 30px;
  margin-top: 0px;
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  color: #3d4465;
}
.navBar.v1 .searchBox div[class*="positionStart"]::after {
  background: rgba(59, 78, 223, 0.05);
  display: none;
}
.navBar.v1 div[class*="h6"] {
  width: calc(100% - 40px);
  display: inline-block;
  padding-left: 40px;
  height: 71px;
  padding-top: 15px;
}
.navBar.v1 + div > div {
  padding: 30px;
  border-radius: 10px;
  margin-left: 100px;
}
.v1-hmenu ul {
  margin-left: 24px;
  padding-top: 25px;
}
.v1-trending {
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .searchHead hr {
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-bottom: 20px;
}
.v1-trending #brandTable .tracker {
  margin-top: 30px !important;
  width: 100% !important;
  box-shadow: none !important;
}
.v1-trending #brandTable .tracker table td:nth-child(5) {
  max-width: 225px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v1-trending #brandTable .tracker table th,
.v1-trending #brandTable .tracker table td {
  padding-left: 30px;
}
.v1-trending #brandTable .tracker table {
  margin: 0px !important;
  width: 100%;
}
.v1-trending .searchHead h4 {
  font-size: 30px !important;
  padding-bottom: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .searchHead h4 a,
.v1-trending .searchHead h4 strong,
.v1-trending .searchHead h4 a small {
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .searchHead h4 a {
  text-decoration: underline;
}
.v1-trending .searchHead h6 {
  font-size: 20px !important;
  margin-bottom: 10px;
  font-weight: 500;
}
.v1-trending .searchHead + h6 {
  font-size: 14px !important;
  margin-bottom: 20px;
  font-weight: 400;
}
.v1-trending .resultBox h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  font-family: "Poppins", sans-serif !important;
  margin-top: 10px;
}
.v1-trending .resultBox h5 strong {
  font-weight: 500;
}
.v1-trending .resultBox h5 + p + div div[class*="Chip"] {
  background: rgba(59, 78, 223, 0.1);
  border-radius: 27px;
  height: 30px;
  font-weight: normal;
  font-size: 14px;
  line-height: 29px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .resultBox h5 + p + div div[class*="Chip"] span {
  font-size: 14px;
}
.v1-trendingItems {
  position: relative;
  /* justify-content: right; */
}
.v1-trendingItems .resultBox > div div[class*="Avatar"] {
  width: 134px;
  height: 134px;
  background-color: rgba(59, 78, 223, 0.5);
  border: none;
  margin-top: 14px;
}
.v1-trendingItems .resultBox > div div[class*="Avatar"] img {
  width: 108px;
  height: 108px;
  border-radius: 50%;
}
.v1-trendingItems .sortProduct {
  position: relative;
  right: 20px;
  white-space: nowrap;
  display: inline-block;
  width: auto;
}
.sortedItems {
  display: inline-block;
  width: 100%;
  margin-top: -45px;
  text-align: right;
}
.viewCheckboxDiv {
  display: inline-block;
  width: auto;
  height: 50px;
  vertical-align: top;
  min-width: 500px;
}
.v1-trendingItems
  .sortProduct
  .countryAutoComplete
  label[class*="formControl"] {
  height: 30px;
  line-height: 30px;
}
.v1-trendingItems .sortProduct .countryAutoComplete {
  max-width: 90%;
  width: auto;
}
.v1-trending .resultBox > div {
  height: 540px;
}
.v1-trending .searchHead + div button[class*="Primary"] {
  background: #683bb7;
  border-radius: 5px;
  min-height: 40px;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.v1-trending .searchHead + div button[class*="Primary"] span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
}
.v1-trending .searchHead + div button[class*="outlinedSizeSmall"] {
  background: #ffffff;
  border: 1px solid #683bb7 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
}
.v1-trending .searchHead + div button[class*="outlinedSizeSmall"] span {
  color: #683bb7 !important;
}
.searchHead + div {
  position: relative;
  z-index: 99999;
  max-width: 30%;
  flex-basis: content;
}
.v1-trending .boxFooter button {
  background: rgba(59, 78, 223, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.v1-trending .resultBox h5 + p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #3d4465 !important;
  font-family: "Poppins", sans-serif !important;
  white-space: nowrap;
  max-width: 90%;
  margin-bottom: 20px !important;
}
.v1-trending .coverImg {
  border-radius: 15px;
  height: 239px;
}
.v1-trending .boxFooter button:nth-child(2) span {
  position: absolute !important;
  right: 7px;
  top: 9px;
}
.v1-trending .boxFooter button svg,
.v1-trending .boxFooter button:nth-child(2) svg {
  fill: #3b4edf;
  width: 16px;
  height: 16px;
}
.v1-trending .avatarImg {
  width: 198px !important;
  height: 198px !important;
  top: -99px !important;
}
.v1-trending .brandTitle > div {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.v1-trending .brandTitle h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465 !important;
  margin-bottom: 5px;
}
.v1-trending .brandTitle h6 {
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 15px;
}
.v1-trending .brandTitle div[class*="brandTags"] div,
.v1-trending .brandTitle div[class*="MuiChip-root"] {
  background: rgba(59, 78, 223, 0.1);
  border-radius: 12.5px;
  height: 25px;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif !important;
  color: #3b4edf;
}
.v1-trending .BrandInfoBtn {
  position: absolute;
  right: 80px;
  margin-top: 30px;
}
.v1-trending button[class*="Primary"] {
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #3b4edf;
  text-transform: none;
  box-shadow: 0px 3px 3px #ccc;
  background: #3b4edf;
  color: #fff;
}
.v1-trending .shbox hr[class*="Divider"] + div > button[class*="Primary"],
.v1-trending
  .shbox
  hr[class*="Divider"]
  + div
  > button[class*="Primary"]
  + div
  button:first-child {
  background: rgba(59, 78, 223, 0.1) !important;
}
.v1-trending button[class*="Primary"] span,
.v1-trending button[class*="outlinedSizeSmall"] span,
.v1-trending a[class*="outlinedSizeSmall"] span,
.v1-trending .durationFilter > div > span + div div {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 16px;
  /* color: #3B4EDF; */
}
.v1-trending button[class*="outlinedSizeSmall"],
.v1-trending a[class*="outlinedSizeSmall"] {
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #3b4edf;
  text-transform: none;
  box-shadow: 0px 2px 3px #ccc;
  background: #fff;
  max-height: 100%;
  color: #3b4edf;
}
.v1-trending .durationFilter > div > span + div {
  border-radius: 5px !important;
  height: 40px;
  font-size: 16px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: 1px solid #3b4edf !important;
  text-transform: none !important;
  box-shadow: 0px 2px 3px #ccc !important;
  background: #fff !important;
  max-height: 100%;
  color: #3b4edf;
}
.v1-trending .searchBrandPaper h6 strong {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 16px;
  color: #3d4465;
}
.v1-trending .searchBrandPaper .addFilters + button {
  background: #ffffff;
  border: 1px solid #3b4edf;
  box-sizing: border-box;
  border-radius: 5px;
  height: 50px;
  min-width: 161px;
  color: #3b4edf;
  padding-left: 20px;
  padding-right: 20px;
}
.v1-trending .durationFilter > div > span + div > div div {
  top: 18px;
  left: 15px;
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 16px;
  color: #3b4edf;
}
.v1-trending .searchBrandPaper .addFilters + button span[class*="startIcon"] {
  width: 16px;
  height: 16px;
  border: 1px solid #3b4edf;
  border-radius: 50%;
}
.v1-trending button[class*="outlinedSizeSmall"] span,
.v1-trending a[class*="outlinedSizeSmall"] {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  color: #3b4edf;
}
.v1-trending .searchBrandPaper > button svg {
  fill: #3b4edf;
  margin-top: 1px;
  margin-left: 1px;
}
.v1-trending div[aria-label="Brand tabs"] button,
.v1-trending .productTab button[class*="outlined"] {
  border-radius: 5px;
  min-height: 40px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  background: #ebedfc;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border: none;
  min-width: auto;
}
.v1-trending .productTab .starBtn {
  margin-right: 20px !important;
}
.v1-trending .infoSocial > div {
  padding: 0px !important;
}
.v1-trending .shareBtn img {
  width: auto;
  height: auto;
  margin-right: 0px;
}
.v1-trending div[aria-label="Brand tabs"] button span {
  color: #3d4465;
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending div[aria-label="Brand tabs"] button[class*="selected"] {
  background: #683bb7;
  border-radius: 5px;
  min-height: 40px;
  height: 40px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.v1-trending div[aria-label="Brand tabs"] button[class*="selected"] span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
}
.v1-trending span[class*="PrivateTabIndicator"],
.v1-trending span[class*="indicator"] {
  display: none;
}
.v1-trending .productTab {
  margin-top: 60px !important;
}
.v1-trending .productTab div[role="tabpanel"] {
  min-height: 300px;
}
.v1-trending .infoSocial + div {
  display: flex !important;
}
.v1-trending .shbox {
  background: #ffffff !important;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08) !important;
  border-radius: 10px !important;
  min-height: 330px;
  border: none;
}
.v1-trending .reportTab {
  position: relative;
  /* margin:0px!important; */
}
.v1-trending .reportTab > div > p {
  max-width: 100%;
  width: 75%;
}
.v1-trending .reportTab > div > strong {
  max-width: 100%;
  width: 75%;
  display: block;
}
.v1-trending .reportTab .react-multi-carousel-list {
  width: 100%;
  /* display: inline-block; */
}
.v1-trending .KeywordBtns {
  padding: 0px !important;
}
.v1-trending .featuredPdttBtn {
  background: rgba(59, 78, 223, 0.1);
  border-radius: 0px 10px 0px 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #3b4edf;
  border: none;
  height: 40px;
  margin-left: 10px;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 99;
}
.v1-trending .reportTab .pbRptBtn {
  position: absolute;
  right: 24px;
  background: #683bb7;
  border-radius: 5px;
  min-height: 40px;
  height: 40px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
  top: -65px;
  border: none;
  cursor: pointer;
}
.v1-trending .reportTab .infoSocial.shbox br {
  display: none;
}
.v1-trending .reportTab .shbox {
  min-height: 210px;
  /* margin-right: 15px; */
}
.v1-trending .reportTab .ProductChartHead h6 {
  font-weight: 500;
  font-size: 30px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
}
.v1-trending .reportTab .socialIcons {
  margin-top: 15px;
}
.v1-trending .reportTab .shbox > div {
  background: transparent;
  padding: 30px !important;
}
.v1-trending .reportTab .tracker {
  width: 100% !important;
  position: relative;
}
.v1-trending .reportTab .tracker .triggerBtn {
  position: absolute;
  right: 30px;
  top: 20px;
}
.v1-trending .scoreCards div {
  position: relative;
}
.v1-trending .shbox .container.sample {
  position: absolute;
  height: 280px;
  overflow: hidden;
}
.v1-trending .shbox .container.sample .ig-radial-gauge.igr-radial-gauge {
  height: 375px;
  margin-top: -50px;
}
.v1-trending .shbox div[class*="Chip"],
.v1-trending .reportTab div[class*="Chip"],
.v1-trending .adsTab div[class*="Chip"] {
  border: none;
  border-radius: 0px;
  height: auto;
  background: transparent;
  margin-right: 30px;
  margin-top: 20px;
}
.v1-trending .shbox div[class*="Chip"] span,
.v1-trending .reportTab div[class*="Chip"] span,
.v1-trending .adsTab div[class*="Chip"] span {
  padding: 0px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.v1-trending .shbox > div > h6 {
  font-family: "Poppins", sans-serif !important;
  /* margin-top: 230px; */
  margin-bottom: 10px;
}
.v1-trending .gaugeChart div > div[class*="12"] div {
  width: 100% !important;
  height: 280px;
  /* margin-top: 50px; */
  max-width: 100% !important;
  text-align: center;
}
.v1-trending .gaugeChart div > div[class*="12"] div table {
  width: 100% !important;
}
.v1-trending .gaugeChart div > div[class*="12"] svg {
  /* max-width: 100%; */
  max-width: 195px;
  width: 100%;
  margin-right: 0px;
  max-height: 200px;
  height: 200px;
}
.v1-trending .gaugeChart > div > div h6 + svg {
  top: 5px;
  height: auto;
  width: 22px;
  position: relative;
}
.v1-trending .gaugeChart h6 {
  margin-top: 201px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3b4edf;
  text-align: right;
  padding-right: 45px;
}
.v1-trending .gaugeChart h6[class*="subtitle1"] {
  text-align: left;
  padding-right: 0px;
}
.v1-trending .scoreCards {
  margin-bottom: 0px !important;
}
.v1-trending .scoreCards span[class*="caption"] {
  margin-top: 0px !important;
  position: relative;
  top: 15px;
  left: 30px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
}
.v1-trending .infoSocial > div span {
  font-family: "Poppins", sans-serif !important;
  line-height: 24px;
  width: calc(100% - 60px);
  margin-top: 20px;
  color: #3d4465;
}
.v1-trending .infoSocial > div > svg {
  width: 18px;
  height: 18px;
  vertical-align: top;
  margin-top: 23px;
  margin-left: 18px;
  margin-right: 10px;
}
.v1-trending .readmore {
  margin-top: 0px !important;
  margin-left: 18px;
}
.v1-trending .socialIcons {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
  padding-left: 0px;
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}
.v1-trending .socialIcons svg {
  margin-left: 8px;
  margin-right: 5px;
  margin-top: 4px;
}
.v1-trending .starRate {
  margin-left: 0px;
  padding-left: 0px;
  text-align: center;
  margin-top: 20px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .starRate p {
  margin-bottom: 5px !important;
}
.v1-trending .starRate span {
  line-height: 100% !important;
  margin-top: 0px !important;
}
.v1-trending .starRate svg {
  margin: 0px;
  padding: 0px;
}
.v1-trending .starRate > svg path {
  fill: green;
}
.v1-trending .starRate > svg + a {
  font-weight: bold;
  font-size: 13px;
}
.v1-trending .socialIcons a {
  width: auto;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .graphs .shbox {
  margin-right: 30px;
  max-width: calc(33.3333% - 20px) !important;
  flex-basis: calc(33.3333% - 20px) !important;
  margin-bottom: 30px;
}
.v1-trending .graphs .shbox:nth-child(3n) {
  margin-right: 0px !important;
}
.v1-trending .gaugeChart {
  max-width: calc(50% - 30px) !important;
  flex-basis: calc(50% - 30px) !important;
  margin-left: 30px !important;
}
.v1-trending .adsTab {
  margin-top: 30px;
  margin-bottom: 25px !important;
}
.v1-trending .adsTab h5 {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #3d4465;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .adsTab div[role="group"] button {
  background: transparent !important;
  box-shadow: none !important;
}
.v1-trending .adsTab div[role="group"] button span {
  color: #3d4465;
}
.v1-trending .adsTab div + button {
  box-shadow: none !important;
}
.v1-trending h6[role="chartCount"] + div svg g g:first-child {
  display: block;
}
.chartContainer,
.chartContainer svg {
  width: 100% !important;
  height: 220px !important;
  max-height: 100% !important;
  margin-top: 0px !important;
  overflow: visible;
}
.chartContainer svg .jqx-gauge-tick-major,
.chartContainer svg .jqx-gauge-label {
  display: none;
}
.chartContainer svg defs + circle,
.chartContainer svg defs + circle + circle {
  display: none;
}
.chartContainer svg .jqx-gauge-caption + path {
  position: relative;
  fill: url("#image");
}
.chartContainer svg .jqx-gauge-caption + path::after {
  position: absolute;
  content: "";
}
.chartContainer svg .jqx-gauge-caption + path + circle {
  r: 10 !important;
}
.v1-trending .Rvgraph,
.v1-trending .tracker {
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  border: none;
  width: calc(100%);
  min-height: 366px;
  margin-bottom: 30px;
}
.v1-trending .Rvgraph {
  margin-left: 30px;
}
.v1-trending .tracker {
  margin-right: 15px;
}
.OverviewTab .apexchart,
.reportTab .apexchart,
.adsTab .apexchart {
  /* max-width: 400px; */
  margin: 0px auto;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  width: calc(100% - 30px);
}
.OverviewTab .apexchart > div {
  display: inline-block;
  width: 100%;
}
.OverviewTab .apexcharts-canvas {
  width: 100% !important;
  height: auto !important;
}
.Rvgraph .apexcharts-canvas {
  width: 100% !important;
  height: 250px !important;
  max-height: 250px !important;
}
.Rvgraph .apexcharts-canvas svg {
  max-width: 100%;
  height: 340px !important;
}
.Rvgraph .apexcharts-series > path {
  opacity: 0.7;
}
.v1-trending .keywordTable {
  max-height: 726px;
  overflow-y: auto;
  margin-top: 30px;
}
.v1-trending .tracker {
  margin-right: 0px;
  /* margin-bottom: 0px; */
}
.v1-trending .keywordTable.tracker {
  width: calc(100% - 0px);
}
div[role="presentation"][aria-labelledby="transition-modal-title"] {
  z-index: 9999 !important;
  overflow-y: auto;
  overflow-x: auto;
}
.v1-trending .tracker h6 {
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
  font-weight: 500;
  font-size: 24px;
  margin-top: 30px !important;
  padding-left: 30px;
  line-height: 24px;
}
.v1-trending .tracker table {
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 60px);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  margin-top: 20px;
}
.v1-trending .ChartTable.tracker {
  width: 100%;
}
.v1-trending .ChartTable.tracker table {
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 60px);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  margin-top: 30px;
}
.v1-trending .ChartTable thead th {
  height: 50px;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.v1-trending .ChartTable table tr td {
  padding-left: 30px !important;
  padding-right: 30px !important;
  height: 40px;
  font-weight: normal;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .ChartTable table tr {
  background: #fff;
}
.v1-trending .ChartTable table tr:nth-child(2n) {
  background: rgba(59, 78, 223, 0.05);
}
.v1-trending .hoverIcon img {
  /* display: none */
  width: 30px !important;
  height: auto !important;
}
.v1-trending .ProductChartHead h5,
.v1-trending .ProductChartHead h5 span {
  color: #3d4465;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 4px;
}
.v1-trending .ProductChartHead + p {
  color: #3d4465;
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0px;
}
.v1-trending .productTab .productDpdwn > div {
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid rgba(61, 68, 101, 0.2);
  height: 28px;
  line-height: 28px;
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 15px;
}
.v1-trending .productTab .productDpdwn svg {
  top: 7px;
}
.v1-trending .ProductChartHead.mt-3 {
  margin-top: 30px;
}
.v1-trending .BarchartTable {
  margin-left: 30px;
  max-width: calc(50% - 30px);
  padding-left: 0px !important;
}
.v1-trending .BarchartTable table th {
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .BarchartTable table th:last-child {
  text-align: right;
}
.v1-trending .BarchartTable table th:last-child span {
  display: inline-grid;
}
.v1-trending .BarchartTable div {
  width: 85% !important;
  border: none;
  height: 75% !important;
}
.v1-trending .BarchartTable svg {
  height: 150px;
  overflow: visible;
  width: 100% !important;
}
.v1.avtarPopup {
  z-index: 9999;
}
.v1.avtarPopup div[class*="Popover"] {
  right: 38px !important;
  top: 72px !important;
  left: auto !important;
}
.v1-trending + .BrandPagination {
  width: 50%;
  position: relative;
  background: #f2f4fd;
  text-align: right;
  display: inline-block;
  margin-right: -30px !important;
  z-index: 999;
  border-radius: 0px !important;
  padding: 0px 30px !important;
  right: 0px;
  bottom: -30px !important;
  overflow: hidden;
  padding-bottom: 35px !important;
  float: right;
}
.productTabResult .BrandPagination,
.v1-trending .productTab .BrandPagination,
.featuredProducts .BrandPagination,
.v1-trendingItems .BrandPagination {
  position: relative;
  width: 100%;
  bottom: 0px;
  text-align: right;
}
.productTabResult .BrandPagination .pageNumber,
.v1-trending .productTab .BrandPagination .pageNumber,
.featuredProducts .BrandPagination .pageNumber,
.v1-trendingItems .BrandPagination .pageNumber {
  position: absolute;
  top: 10px;
  white-space: nowrap;
  display: inline-block;
  left: 0px;
  width: 50%;
  text-align: left;
}
.productTabResult .BrandPagination .pageNumber label,
.v1-trending .productTab .BrandPagination .pageNumber label,
.featuredProducts .BrandPagination .pageNumber label,
.v1-trendingItems .BrandPagination .pageNumber label {
  width: auto;
  display: inline-block;
}
.productTabResult .BrandPagination .pageNumber div div,
.v1-trending .productTab .BrandPagination .pageNumber div div,
.featuredProducts .BrandPagination .pageNumber div div,
.v1-trendingItems .BrandPagination .pageNumber div div {
  padding-left: 10px;
  margin-left: 10px;
}
.productTabResult .BrandPagination .pageNumber div::after,
.productTabResult .BrandPagination .pageNumber div::before,
.v1-trendingItems .BrandPagination .pageNumber div::after,
.v1-trendingItems .BrandPagination .pageNumber div::before {
  display: none;
}
.v1-trending .productTab .BrandPagination > label:first-child {
  margin-top: 7px;
}
.v1-trending .productTab .BrandPagination > label:first-child,
.v1-trending .productTab .BrandPagination > label:first-child + div {
  float: left;
  font-family: "Poppins", sans-serif !important;
  margin-right: 10px;
}
.v1-trending .productTab .BrandPagination nav,
.v1-trending .productTab .BrandPagination nav + label {
  display: inline-block;
  font-family: "Poppins", sans-serif !important;
  margin-left: 15px;
}
.v1-trending
  .productTab
  .BrandPagination
  nav
  li
  button[class*="selected"]::before {
  display: none;
}
.v1-trending #brandTable tfoot div[class*="selectRoot"] {
  left: 250px;
}
.v1-trending #brandTable tfoot div[class*="selectRoot"] svg {
  top: 11px;
}
.v1-trending #brandTable tfoot div[class*="spacer"] + p {
  padding-left: 10px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending #brandTable tfoot div[class*="selectRoot"] > div,
.v1-trending + .BrandPagination div[aria-labelledby="page-select"],
.v1-trending .productTab .BrandPagination div[aria-labelledby="page-select"],
.productTabResult .BrandPagination div[aria-labelledby="page-select"] {
  /* border: 1px solid #B1B8F2; */
  border-radius: 5px;
  height: 24px;
  line-height: 24px !important;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending #brandTable tfoot div[class*="selectRoot"] + p {
  padding-left: 5px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending #brandTable tfoot td > div {
  background: #f2f4fd;
  width: 50%;
  bottom: -135px;
  left: -60px;
  padding-bottom: 30px;
  border: none;
}
.v1-trending + .BrandPagination ul,
.v1-trending .productTab .BrandPagination ul,
.productTabResult .BrandPagination ul,
.featuredProducts .BrandPagination ul,
.v1-trendingItems .BrandPagination ul {
  border: 1px solid #b1b8f2;
  box-shadow: 0px 10px 40px 5px rgba(23, 3, 89, 0.08);
  border-radius: 5px;
  height: 34px;
  background: #fff;
  overflow: hidden;
}
.v1-trending + .BrandPagination ul li,
.v1-trending .productTab .BrandPagination ul li,
.productTabResult .BrandPagination ul li,
.featuredProducts .BrandPagination ul li,
.v1-trendingItems .BrandPagination ul li {
  height: 34px;
}
.v1-trending + .BrandPagination ul button,
.v1-trending .productTab .BrandPagination ul button,
.productTabResult .BrandPagination ul button,
.featuredProducts .BrandPagination ul button,
.v1-trendingItems .BrandPagination ul button {
  border-right: 1px solid #b1b8f2;
  height: 34px;
  margin: 0px !important;
  min-width: 35px;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  color: #3d4465;
  border: none;
}
.v1-trending .brandAvatar div[class*="circular"] + strong {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 16px;
}
.v1-trending h4 {
  font-size: 30px;
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
}
.v1-trending #brandTable tbody div[class*="valueChip"],
.v1-trending #brandTable tbody div[class*="jss37"] {
  padding: 0px;
  min-width: auto;
  background: transparent !important;
}
.v1-trending #brandTable tbody div[class*="valueChip"] span,
.v1-trending #brandTable tbody div[class*="jss37"] span {
  padding: 0px;
  min-width: auto;
  color: rgba(61, 68, 101, 0.7);
}
.v1-trending + .BrandPagination ul li button[class*="selected"],
.v1-trending .productTab .BrandPagination ul li button[class*="selected"],
.productTabResult .BrandPagination ul li button[class*="selected"],
.featuredProducts .BrandPagination ul li button[class*="selected"],
.v1-trendingItems .BrandPagination ul li button[class*="selected"] {
  background: #3b4edf !important;
  color: #fff !important;
}
.v1-trending + .BrandPagination ul li:last-child button,
.productTabResult .BrandPagination ul li:last-child button,
.v1-trendingItems .BrandPagination ul li:last-child button,
.featuredProducts .BrandPagination ul li:last-child button {
  border: none !important;
}
.v1-trending + .BrandPagination nav,
.v1-trending + .BrandPagination label#page-select,
.productTabResult .BrandPagination label#page-select,
.featuredProducts .BrandPagination label#page-select,
.v1-trendingItems .BrandPagination label#page-select,
.productTabResult .BrandPagination nav,
.featuredProducts .BrandPagination nav,
.v1-trendingItems .BrandPagination nav,
.v1-trendingItems
  .BrandPagination
  ul
  li
  button[class*="selected"]
  .featuredProducts
  .BrandPagination
  ul
  li
  button[class*="selected"] {
  display: inline-block;
  padding-left: 15px;
  font-family: "Poppins", sans-serif !important;
}
.v1-trending + .BrandPagination div {
  width: auto;
}
img[alt="Settings"] + label > div > div {
  background: transparent;
}
.v1-trending .reportTab .tracker table th,
.v1-trending .reportTab .tracker table td {
  text-align: center;
}
.v1-trending .reportTab .tracker table tr:first-child th:first-child,
.v1-trending .reportTab .tracker table tr td:first-child {
  text-align: left;
}
.v1-trending .tracker table[aria-aria-label="spanning table"] td,
.v1-trending .reportTab .tracker table td,
.v1-trending #brandTable .tracker table td {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  border: none;
  height: 34px;
}
.v1-trending .bestSeller .hoverIcon {
  /* left:15px;
  right:auto!important; */
}
.v1-trending .bestSeller button[class*="Primary"] {
  top: -5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  padding-top: 2px;
}
.v1-trending .bestSeller button[class*="Primary"] span {
  font-size: 14px;
}
.v1-trending .reportTab .reportTable {
  margin-left: 0px;
  box-shadow: none;
  margin-top: 0px;
}
.v1-trending
  .tracker
  table[aria-aria-label="spanning table"]
  tr:nth-child(even),
.v1-trending .reportTab .tracker table tr:nth-child(even),
.v1-trending #brandTable .tracker table tr:nth-child(even) {
  background: rgba(59, 78, 223, 0.05);
}
.v1-trending .tracker table th {
  background: rgba(59, 78, 223, 0.1);
  color: #3d4465;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  line-height: 27px;
  padding-left: 15px;
  padding-right: 15px;
}
.v1-trending .SocialTab div[class*="Paper-outlined"] {
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  border: none;
  min-height: 58px;
  line-height: 58px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  display: inline-block;
  width: calc(100% - 60px);
}
.v1-trending .SocialTab div[class*="Paper-outlined"] h6 {
  font-weight: 500;
  font-size: 20px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
  display: inline-block;
  margin-bottom: 0px;
  min-width: 280px;
  position: relative;
}
.v1-trending .SocialTab div[class*="Paper-outlined"] h6::after {
  position: absolute;
  right: -20px;
  border-right: 1px solid #3b4edf;
  opacity: 0.5;
  height: 55px;
  content: "";
  top: -22.5px;
}
.v1-trending .SocialTab div[class*="Paper-elevation1"] h6 {
  font-weight: 500;
  font-size: 20px;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
  display: inline-block;
  margin-bottom: 0px;
  min-width: 280px;
  position: relative;
}
.v1-trending .SocialTab div[class*="Paper-elevation1"] h6::after {
  position: absolute;
  right: -20px;
  border-right: 1px solid #fff;
  opacity: 0.5;
  height: 55px;
  content: "";
  top: -22.5px;
}
.v1-trending .SocialTab div[class*="Paper-outlined"] h4 {
  font-weight: 500;
  font-size: 30px;
  font-family: "Poppins", sans-serif !important;
  color: #3b4edf;
  display: inline-block;
  min-width: calc(100% - 280px);
  text-align: right;
  vertical-align: middle;
}
.v1-trending .SocialTab div[class*="Paper-elevation1"] {
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  border: none;
  background: #3b4edf;
  color: #fff;
  min-height: 58px;
  line-height: 58px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  display: inline-block;
  width: calc(100% - 60px);
}
.v1-trending .SocialTab div[class*="Paper-elevation1"] h4 {
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  font-family: "Poppins", sans-serif !important;
  display: inline-block;
  min-width: calc(100% - 280px);
  text-align: right;
  vertical-align: middle;
}
.v1-trending .SocialTab {
  margin-top: 40px;
}
.v1-trending .SocialTab text.apexcharts-datalabel {
  opacity: 0;
  /* display: none; */
}
.v1-trending .SocialTab .apexcharts-data-labels rect {
  width: 12px;
  height: 12px;
  ry: 50;
  rx: 50;
  transform: translate(12px, 5px);
}
.v1-trending .SocialTab .apexchart {
  margin: 0px;
  width: calc(100% - 60px);
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  max-width: 100%;
  padding: 30px;
  height: 470px;
  /* padding-top: 60px; */
}
.v1-trending .SocialTab .apexchart > div {
  min-height: 470px !important;
  height: 470px;
  width: 770px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.v1-trending .SocialTab .apexchart > div > div {
  max-height: 470px;
  height: 470px !important ;
  max-width: 770px !important;
}
.v1-trending .SocialTab .apexchart div div svg {
  min-height: 470px;
  max-height: 470px !important ;
  max-width: 770px !important;
}
.v1-trending .SocialTab .apexchart .apexcharts-gridline,
.v1-trending .adsTab .apexcharts-gridline {
  stroke: rgba(59, 78, 223, 0.2);
  opacity: 1;
  stroke-dasharray: 6 !important;
}
.v1-trending .adsTab div[aria-haspopup="listbox"] {
  padding-bottom: 9px;
  padding-top: 8px;
  border-radius: 0px 5px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 16px;
}
.v1-trending .adsTab div[class*="Controller"] > div,
.v1-trending .adsTab div[class*="grid-xs-8"] > div {
  border-radius: 5px 0px 0px 5px !important;
}
.v1-trending .adsTab div[class*="Controller"] > div + button,
.v1-trending .adsTab div[class*="grid-xs-8"] > div + button {
  border: 1px solid #ccc;
  border-radius: 0px 5px 5px 0px !important;
  border-left: none;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
.v1-trending .adsTab div[class*="Controller"] > div + button span,
.v1-trending .adsTab div[class*="grid-xs-8"] > div + button span {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 16px;
}
.v1-trending .SocialTab .apexchart svg .apexcharts-series > path {
  opacity: 0.6;
}
.v1-trending .SocialTab .apexchart svg .apexcharts-text tspan {
  font-weight: normal;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  color: #8496b5;
}
.v1-trending .SocialTab button[class*="outlined"]:first-child {
  background: transparent !important;
  width: calc(100% - 225px) !important;
  border: 1px solid #d6d7da !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}
.v1-trending .SocialTab button[class*="outlined"]:first-child + div > div {
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 15px !important;
}
.v1-trending .SocialTab button[class*="outlined"]:first-child + div {
  border: 1px solid #d6d7da !important;
}
.v1-trending .SocialTab button[class*="outlined"]:first-child + div fieldset {
  opacity: 0;
}
.v1-trending .SocialTab button[class*="outlined"]:last-child {
  background: transparent !important;
  border: 1px solid #d6d7da !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}
.v1-trending .SocialTab button[class*="outlined"]:first-child > span,
.v1-trending .SocialTab button[class*="outlined"]:last-child > span {
  color: #3d4465;
}
.v1-trending .adsTab .apexchart {
  margin: 0px;
}
.v1-trending .adsTab svg path[fill="#08306b"] {
  fill: #3b4edf !important;
}
.v1-trending .reportTab svg path[fill="#08306b"] {
  fill: #3b4edf !important;
}
.v1-trending .adsTab svg path[fill="#eaeef3"] {
  fill: rgba(59, 78, 223, 0.1) !important;
}
.v1-trending .reportTab svg path[fill="#eaeef3"] {
  fill: rgba(59, 78, 223, 0.1) !important;
}
.v1-trending .adsTab div[class*="Paper-rounded"] {
  z-index: 99;
}
.v1-trending .adsTab .adsFilterHead,
.v1-trending .reportTab .adsFilterHead {
  margin-top: 30px !important;
  margin-bottom: 25px !important;
}
.v1-trending .adsTab .adItems > div,
.v1-trending .reportTab .adItems > div {
  background: #ffffff !important;
  box-shadow: 0px 5px 20px 3px rgba(23, 3, 89, 0.08) !important;
  border-radius: 10px !important;
  padding: 20px;
}
.v1-trending .adsTab .adsheader,
.v1-trending .reportTab .adsheader {
  display: inline-block;
  width: calc(100% - 0px);
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 25px;
}
.v1-trending .adsTab .adItems {
  max-width: calc(25% - 25px);
  padding: 0px !important;
  position: relative;
}
.v1-trending .reportTab .adItems {
  max-width: calc(100% - 20px);
  padding: 0px !important;
  margin: 0px !important;
}
.v1-trending .adsTab .adsheader > span,
.v1-trending .reportTab .adsheader > span {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
}
.v1-trending .avgRunningTime {
  padding: 30px;
}
.v1-trending .avgRunningTime.Active h6 svg {
  fill: #069d6d;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #069d6d;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.v1-trending .avgRunningTime p {
  padding-left: 0px;
  margin-top: 25px;
  font-weight: normal;
  font-size: 12px;
  color: #3d4465;
  margin-bottom: 20px;
}
.v1-trending .avgRunningTime p span {
  font-weight: 500;
  font-size: 16px;
  color: #3d4465;
  padding: 0px;
}
.v1-trending .avgRunningTime.Inactive h6 svg {
  fill: #fff;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid rgba(59, 78, 223, 0.1);
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background: rgba(59, 78, 223, 0.1);
}
.v1-trending .adsTab .adItems .adsImg,
.v1-trending .reportTab .adItems .adsImg {
  margin: 0px !important;
  width: 100% !important;
  height: 312px !important;
  background: #d3d3d3;
  border-radius: 5px;
  position: relative;
}
.v1-trending .adsTab .adItems .adsImg iframe,
.v1-trending .adsTab .adItems .adsImg video,
.v1-trending .reportTab .adItems .adsImg iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  left: 0px;
  top: 0px;
}
.v1-trending .adsTab .adItems div[class*="Avatar"],
.v1-trending .reportTab .adItems div[class*="Avatar"] {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.v1-trending .adsTab .adItems div[class*="Avatar"] + a,
.v1-trending .reportTab .adItems div[class*="Avatar"] + a {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3d4465;
  font-family: "Poppins", sans-serif !important;
  text-decoration: none;
}
.v1-trending .adsTab .adItems > button {
  position: absolute;
  right: 0px;
  top: 5px;
  border: none;
}
.v1-trending .adsTab .adItems div[class*="Avatar"] + a + button + p,
.v1-trending .adsTab .adItems div[class*="Avatar"] + a + p,
.v1-trending .reportTab .adItems div[class*="Avatar"] + a + p {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  line-height: 18px;
  color: #3d4465;
  height: 54px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.v1-trending .adsTab .adsheader span.Active svg,
.v1-trending .reportTab .adsheader span.Active svg {
  fill: #069d6d;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #069d6d;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.v1-trending .adsTab .adsheader span.Inactive svg {
  fill: #fff;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid rgba(59, 78, 223, 0.1);
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background: rgba(59, 78, 223, 0.1);
}
.v1-trending .adsTab .adsheader > span,
.v1-trending .reportTab .adsheader > span {
  width: calc(100% - 118px);
}
.v1-trending .adsTab .adsheader span.Active + a,
.v1-trending .reportTab .adsheader span.Active + a,
.v1-trending .adsTab .adsheader span.Inactive + a {
  border: 1px solid #3b4edf;
  box-sizing: border-box;
  border-radius: 5px;
  height: 40px;
  min-width: 118px;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  font-family: "Poppins", sans-serif !important;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-top: -20px;
}
.v1-trending .adsTab .adsheader div.sdate,
.v1-trending .reportTab .adsheader div.sdate {
  margin-top: 10px;
  color: #3d4465;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  width: 100%;
}
.v1-trending .adsTab .adsheader div.sdate span,
.v1-trending .reportTab .adsheader div.sdate span {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  color: #3d4465;
}
.v1-trending .adsTab .adsheader div,
.v1-trending .reportTab .adsheader div {
  margin-left: 0px;
}
.v1-trending .adsTab .adsheader div.sIcons,
.v1-trending .reportTab .adsheader div.sIcons {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #3b4edf;
  float: right;
  margin-left: 8px;
  text-align: center;
  line-height: 18px;
}
.v1-trending .adsTab .customSort {
  margin-left: 30px;
  box-shadow: none;
}
.v1-trending .adsTab .adsFilterHead button {
  background: transparent !important;
  border: 1px solid #ddd;
}
.v1-trending .adsTab .adsheader div.sIcons i,
.v1-trending .reportTab .adsheader div.sIcons i {
  margin-left: 0px;
  color: #3b4edf;
  font-size: 12px;
}
.v1-trending .adsTab .adsheader div.sIcons:hover,
.v1-trending .reportTab .adsheader div.sIcons:hover {
  background: #3b4edf;
}
.v1-trending .adsTab .adsheader div.sIcons:hover i,
.v1-trending .reportTab .adsheader div.sIcons:hover i {
  color: #fff;
}
.adsheader div.ldate + p {
  margin-top: 5px;
  color: #3d4465;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  display: inline-block;
  margin-bottom: 0px;
}
.v1-trending .ig-radial-gauge.igr-radial-gauge canvas {
  position: relative !important;
}
.v1-trending .StoreHeader .apexchart {
  max-width: 100%;
  margin: 0px;
}
.v1-trending .StoreHeading,
.v1-trending .StoreHeader + div {
  border: none;
  width: 100%;
  margin: 0px;
}
.v1-trending .StoreDataList {
  padding: 0px;
  margin: 0px;
  border: none;
}
.v1-trending .StoreDataList li {
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  border: none;
  padding: 20px;
  min-height: 110px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  color: #3d4465;
}
.v1-trending .StoreDataList li svg {
  fill: #683bb7;
  position: relative;
  top: 5px;
  left: 5px;
}
.v1-trending .ActSettings h3 {
  margin-bottom: 15px;
}
.v1-trending .ActSettings .plans table td:first-child {
  text-align: left;
}
.v1-trending .ActSettings .plans table td {
  text-align: center;
}
.v1-trending .StoreDataList li div span {
  color: #3b4edf;
  font-weight: 500;
  font-size: 26px;
}
.v1-trending .storeGraphBox {
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  height: 500px;
  width: 100%;
  display: inherit;
  padding: 30px;
  margin-bottom: 30px;
}
.v1-trending .SerAdsHead > div {
  grid-gap: 30px;
  flex-wrap: wrap;
  display: flex;
}
.v1-trending .KeywordChartHead,
.v1-trending .SerAdsHead {
  border: none;
}
.v1-trending .SerAdsHead {
  margin-bottom: 22px;
}
.v1-trending .SerAdsHead h5,
.v1-trending .KeywordChartHead h5 {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #3d4465;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 20px;
}
.v1-trending .KeywordBtns button {
  background: #ffffff !important;
  border: 1px solid #3b4edf !important;
  box-sizing: border-box;
  border-radius: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-top: 0px;
  color: #3b4edf !important;
  font-family: "Poppins", sans-serif !important;
  margin-right: 20px !important;
  white-space: nowrap;
}
.v1-trending .KeywordBtns .react-multi-carousel-list {
  padding: 0px;
  border-radius: 10px;
  height: 80px;
}
.v1-trending .KeywordBtns button.react-multiple-carousel__arrow--left {
  left: 8px !important;
  background: #3b4edf !important;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
}
.v1-trending .KeywordBtns button.react-multiple-carousel__arrow--right {
  right: 8px !important;
  background: #3b4edf !important;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
  margin-right: 0px !important;
}
.v1-trending .KeywordBtns .react-multi-carousel-list::before {
  background: linear-gradient(
    90deg,
    #f8f8f8 0%,
    rgba(248, 248, 248, 0.95) 48.63%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 10px 0px 0px 10px;
  height: 80px;
  content: "";
  left: -15px;
  top: 0px;
  width: 100px;
  position: absolute;
  z-index: 9;
}
.v1-trending .KeywordBtns .react-multi-carousel-list::after {
  background: linear-gradient(
    90deg,
    #f8f8f8 0%,
    rgba(248, 248, 248, 0.95) 48.63%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 10px 0px 0px 10px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 80px;
  content: "";
  right: -15px;
  top: 0px;
  width: 100px;
  position: absolute;
  z-index: 9;
}
.v1-trending .KeywordBtns button.react-multiple-carousel__arrow--left::before {
  left: -6px;
}
.v1-trending .KeywordBtns button.react-multiple-carousel__arrow--right::before {
  right: 1px;
}
.v1-trending .KeywordBtns .react-multi-carousel-item {
  width: auto !important;
}
.v1-trending .KeywordBtns button span {
  font-family: "Poppins", sans-serif !important;
}
.v1-trending .KeywordBtns button[class*="Primary"] {
  background: #3b4edf !important;
  color: #fff !important;
}
.v1-trending .KeywordBtns .Btnscroll {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  display: inline-block;
}
.v1-trending .apexbarchart .apexcharts-series path {
  /* stroke-linejoin: round;
  transform: rotateY(71deg);
  transform-origin: 50%;
  transform-box: fill-box; */
}
.v1-trending .apexbarchart .apexcharts-xcrosshairs {
  /* transform: rotateY(71deg);
  transform-origin: 100%;
  transform-box: fill-box; */
}
.v1-trending .SerAdsHead .SerAds {
  flex-grow: 0;
  max-width: calc(33.333% - 20px);
  flex-basis: calc(33.33% - 20px);
  border: none !important;
}
.v1-trending .SerAdsHead .SerAds > div {
  vertical-align: top;
  padding: 30px;
  border: none;
  border-right: none !important;
  margin-bottom: 0px;
  border-collapse: collapse;
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  height: 200px;
  /* overflow: hidden; */
}
.v1-trending .SerAdsHead .SerAds h4 {
  height: 48px;
  margin-bottom: 5px;
}
.v1-trending .SerAdsHead .SerAds h4 a {
  white-space: normal;
  padding: 0px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #3b4edf;
}
.v1-trending .SerAdsHead .SerAds h4 + a {
  padding: 0px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #069d6d !important;
  text-decoration-line: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 30px;
}
.v1-trending .SerAdsHead .SerAds p {
  padding: 0px;
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  height: 42px;
  overflow: hidden;
  color: #3d4465;
  margin-bottom: 30px;
  min-height: 42px;
}
.v1-trending .SerAds .keywordTab {
  margin-top: 20px;
}
.v1-trending .SerAds .keywordTab table {
  margin-left: 0px;
  margin-right: 0px;
  width: calc(100% - 0px);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  margin-top: 0px;
}
.v1-trending .SerAds .keywordTab table th {
  background: rgba(59, 78, 223, 0.1);
  color: #3d4465;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  line-height: 27px;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
}
.v1-trending .SerAds .keywordTab h5 {
  margin-top: 0px;
  padding-left: 0px;
}
.v1-trending .SerAds .keywordTab table td {
  height: 40px;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  border: none;
}
.v1-trending .SerAds .keywordTab table tr:nth-child(even) {
  background: rgba(59, 78, 223, 0.05);
}
.v1-trending .SerAds:nth-child(4n-3) .keywordTab {
  width: calc(300% + 0px);
  margin-left: 0px;
}
.v1-trending .SerAds:nth-child(4n-2) .keywordTab {
  width: calc(300% + 0px);
  margin-left: calc(-100% - 30px);
  border-left: 1px solid #e3e8ee;
}
.v1-trending .SerAds:nth-child(4n + 3) .keywordTab {
  width: calc(300% + 0px);
  margin-left: calc(-200% - 60px);
  border-left: 1px solid #e3e8ee;
}
.v1-trending .SerAds:nth-child(4n) .keywordTab {
  width: calc(300% + 3px);
  margin-left: 0px;
}
.v1-trending .GoogleAds .SerAds button {
  width: calc(100% + 60px) !important;
  margin-left: -30px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0px;
  height: 47px;
  background: rgba(59, 78, 223, 0.1);
  border-radius: 0px 0px 10px 10px !important;
}
.v1-trending .GoogleAds .SerAds button[class*="Primary"] span {
  color: #fff;
}
.v1-trending .GoogleAds .SerAds button[class*="Primary"] span span {
  top: 1px !important;
}
.v1-trending #brandTable div h4 + div button.selectedBtn span {
  color: #fff !important;
}
.v1-trending .GoogleAds .SerAds button span {
  padding: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
}
.v1-trending .GoogleAds .SerAds button span span {
  margin-right: -8px;
  margin-top: 5px;
  top: 1px;
  position: relative;
  font-size: 24px !important;
}
.v1-trending .GoogleAds .SerAds button span span svg {
  font-size: 24px !important;
}
.v1-trending .productBox {
  position: relative;
}
.v1-trending .productBox div button {
  position: relative;
  top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 20px;
  padding-top: 0px !important;
  font-size: 12px;
  border-radius: 4px !important;
  line-height: 22px !important;
  font-size: 12px;
  white-space: nowrap;
}
.v1-trending .productBox > div,
.v1-trending .bestSeller > div {
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  display: block;
}
.v1-trending .productBox {
  margin: 0px !important;
  max-width: calc(25% - 23px) !important;
  flex-basis: calc(25% - 23px) !important;
}
.v1-trending .productTabResult .productBox {
  margin: 0px !important;
  max-width: calc(25%) !important;
  flex-basis: calc(25%) !important;
}
.v1-trending .productBox > div:hover {
  border: none;
}
.v1-trending .productBox div[class*="Avatar-square"],
.v1-trending .bestSeller div[class*="Avatar-square"] {
  height: 240px;
  background: #fff;
  border-radius: 5px;
}
.v1-trending .productBox div[class*="Avatar-square"] img,
.v1-trending .bestSeller div[class*="Avatar-square"] img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.v1-trending .productBox h6[class*="subtitle2"],
.v1-trending .bestSeller h6[class*="subtitle2"] {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3d4465;
  font-family: "Poppins", sans-serif !important;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: auto;
  margin-bottom: 5px !important;
}
.v1-trending .productBox .pdtDetails,
.v1-trending .bestSeller .bstSellerDetails {
  display: inline-block;
  width: 65%;
  margin-top: 20px;
}
.v1-trending .productBox .pdtDetails span a,
.v1-trending .bestSeller .bstSellerDetails span a {
  font-size: 16px;
  line-height: 24px;
  color: #3d4465;
}
.v1-trending .productBox .pdtDetails + h6,
.v1-trending .bestSeller .bstSellerDetails + h6 {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  font-family: "Poppins", sans-serif !important;
  color: #3b4edf;
  display: inline-block;
  width: 35%;
  text-align: right;
}
.Rvgraph .apexcharts-legend-marker {
  border-radius: 3px !important;
  width: 16px !important;
  height: 16px !important;
}
.OverviewTab .apexcharts-area-series .apexcharts-area {
  stroke-width: 3 !important;
}
.OverviewTab .apexcharts-canvas {
  position: relative;
  user-select: none;
  max-width: 100%;
}
.OverviewTab .apexcharts-canvas svg {
  max-width: 100%;
  /* height: 240px!important; */
}
.OverviewTab .apexcharts-series path:nth-child(1),
.adsTab .apexcharts-series path:nth-child(1) {
  opacity: 0.5;
}
.OverviewTab .apexcharts-gridline {
  stroke: rgba(59, 78, 223, 0.2);
  opacity: 1;
  stroke-dasharray: 6 !important;
}
.Rvgraph .apexcharts-gridline {
  stroke: rgba(59, 78, 223, 0.2);
  opacity: 1;
  stroke-dasharray: 6 !important;
}
.OverviewTab .apexcharts-xcrosshairs,
OverviewTab .apexcharts-ycrosshairs {
  /* opacity:1 !important; */
}
OverviewTab .apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-label,
.OverviewTab .apexcharts-tooltip-title,
.apexcharts-tooltip-marker,
.apexcharts-xaxistooltip.apexcharts-xaxistooltip-bottom.apexcharts-theme-light {
  /* display:none;
  opacity: 0 !important; */
}
.OverviewTab .apexcharts-text tspan {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.OverviewTab .apexcharts-tooltip-series-group.apexcharts-active {
  /* background: #4F49E4;
  border-radius: 70px;
  color:#fff;
  padding:0px 10px!important; */
}
.OverviewTab .apexcharts-tooltip-text-y-value {
  margin-left: 0px !important;
}
.OverviewTab .apexcharts-tooltip {
  /* border:none!important;
  border-radius: 70px!important; */
}
.svg-item {
  max-width: 50px;
  display: inline-block;
}
.svg-item svg {
  width: 100%;
  height: 100%;
}
.donut-ring {
  stroke: #ebebeb;
}
.donut-segment-3 {
  /* stroke: #FBA04B; */
  animation: donut2 3s;
  /* border-radius: 10px; */
  stroke-linecap: round;
}
.donut-text-2 {
  /* fill: #FBA04B; */
}
.donut-percent {
  font-size: 12px;
  line-height: 1;
  transform: translateY(0.5em);
  font-weight: 500;
}
.donut-data {
  font-size: 0.12em;
  line-height: 1;
  transform: translateY(0.5em);
  text-align: center;
  text-anchor: middle;
  color: #666;
  fill: #666;
  animation: donutfadelong 1s;
}
.svg-item + h6 {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 18px;
  /* identical to box height */
  color: #3d4465 !important;
  font-family: "Poppins", sans-serif !important;
  margin-top: 20px !important;
}
.svg-item + h6 svg {
  position: relative;
  top: 6px;
  left: 5px;
  width: 15px;
  cursor: help;
}
.v1-trending span[role="slider"][aria-orientation="horizontal"]::after {
  top: 0px;
  left: 5px;
  right: -15px;
  bottom: -15px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background: #fff;
  width: 24px;
  height: 24px;
}
.v1-trending span[class*="Slider-rail"] {
  background: rgba(59, 78, 223, 0.1) !important;
  border-radius: 6px;
}
.v1-trending .paperSelect > div {
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
  height: 50px;
}
.v1-trending .paperSelect div[aria-haspopup="listbox"],
.v1-trending .countryAutoComplete label[class*="formControl"] {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
}
.v1-trending .countryAutoComplete [class*="fullWidth"] {
  min-height: 50px;
}
.v1-trending .countryAutoComplete [class*="fullWidth"] div[role="button"] {
  background: #f3f3f3;
  border-radius: 20px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 0px;
}
.sortProduct .countryAutoComplete [class*="fullWidth"] div[role="button"] {
  padding-right: 5px !important;
  padding-left: 0px !important;
}
.v1-trending .countryAutoComplete [class*="fullWidth"] div[role="button"] span {
  font-size: 16px !important;
  font-weight: normal;
}
.sortProduct .countryAutoComplete [class*="fullWidth"] div[role="button"] span {
  font-size: 11px !important;
}
.v1-trending .countryAutoComplete [class*="fullWidth"] div[role="button"] svg {
  position: relative;
  right: -6px;
}
.v1-trending span[class*="Slider-rail"]:before,
.v1-trending span[class*="Slider-rail"]:after {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  color: #3b4edf;
  top: -10px;
}
.v1-trending .searchBrandPaper span[role="slider"][data-index="0"] {
  background: none;
  border: none;
  margin-top: -9px;
  margin-left: -7px;
}
.v1-trending .searchBrandPaper span[role="slider"][data-index="1"] {
  margin-left: -32px;
}
.v1-trending .countryAutoComplete {
  width: calc(100% - 80px);
  margin-left: 30px !important;
}
.v1-trending .ProductDetailPage,
.v1-trending .ProductAnalysis {
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  padding: 30px;
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}
.v1-trending .ProductAnalysis .RangeSlider {
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  padding: 30px;
  display: inline-block;
  width: 100%;
  min-height: 200px;
}
.v1-trending .ProductAnalysis h5 {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #3d4465;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 30px;
}
.v1-trending .ProductAnalysis button {
  background: #ebedfc;
  border-radius: 5px;
  height: 40px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  padding-left: 18px;
  padding-right: 18px;
  margin-right: 20px;
  color: #3d4465;
  margin-bottom: 25px;
  cursor: pointer;
}
.v1-trending .hoverIcon {
  right: 30px !important;
  top: -3px;
}
.v1-trending .ProductAnalysis button.addSupplierBtn,
.v1-trending .Competitors button.addSupplierBtn {
  position: absolute;
  right: 30px;
  background: #fff !important;
  border: 1px solid #1976d2 !important;
  color: #1976d2;
  top: 30px;
  margin-right: 0px;
  margin-bottom: 25px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 5px;
  height: 40px;
}
.v1-trending .Competitors button.addSupplierBtn {
  /* right:305px !important; */
}
.v1-trending .Competitors button.filterbtn {
  position: absolute;
  background: #ebedfc;
  border: 1px solid #ebedfc !important;
  border-radius: 5px 0px 0px 5px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  padding-left: 18px;
  padding-right: 18px;
  margin-right: 20px;
  color: #3d4465;
  margin-bottom: 25px;
  cursor: pointer;
  top: 30px;
  right: 160px;
}
.v1-trending .Competitors button.countryBtn {
  position: absolute;
  right: 30px;
  border: 1px solid #ebedfc !important;
  margin-right: 0px;
  margin-bottom: 25px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 0px 5px 5px 0px;
  height: 40px;
  top: 30px;
  color: #3d4465;
  width: 150px;
}
.v1-trending .ProductAnalysis button.addSupplierBtn:hover,
.v1-trending .Competitors button.addSupplierBtn:hover {
  background: #f1f1f1;
}
.v1-trending .ProductAnalysis button.selected {
  background: #683bb7;
  color: #f2f4fd;
}
.v1-trending .ProductAnalysis .ProductAnalysisDetails {
  width: calc(65% - 50px);
  display: inline-block;
  margin-right: 50px;
}
.v1-trending .ProductAnalysis .ProductAnalysisDetails h5 {
  margin-bottom: 20px;
}
.v1-trending .ProductAnalysis .ProductAnalysisDetails p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: 145px;
  overflow: hidden;
}
.v1-trending .ProductAnalysis .RangeSlider div > span {
  width: 80%;
  margin-top: 50px;
  margin-left: 10%;
}
.v1-trending .ProductDetailHead h5 + button,
.v1-trending .reportTab .ProductChartHead h6 span.editIcon {
  background: rgba(59, 78, 223, 0.1);
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #3b4edf;
  border: none;
  height: 30px;
  margin-left: 10px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.v1-trending .reportTab .ProductChartHead h6 span.editIcon svg {
  width: 20px;
  height: 20px;
  margin-top: 4px;
}
.v1-trending .Competitors table td:first-child button {
  background: rgba(59, 78, 223, 0.1);
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  cursor: pointer;
  color: #3b4edf;
  padding-top: 7px;
}
.v1-trending .Competitors table td:first-child button svg {
  color: #1976d2;
  fill: #1976d2 !important;
}
.v1-trending .Competitors h5 + span[role="button"] {
  background: rgba(59, 78, 223, 0.1);
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #3b4edf;
  border: none;
  height: 26px;
  margin-left: 10px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
  padding-top: 4px;
  cursor: pointer;
}
.v1-trending .ProductDetailHead + h5 {
  margin-bottom: 20px;
}
.v1-trending .ProductDetailPage .producImage {
  display: inline-block;
  width: 100%;
  height: 401px;
  background: rgba(59, 78, 223, 0.1);
  border-radius: 20px;
  text-align: center;
  margin-bottom: 20px;
  vertical-align: top;
}
.v1-trending .ProductDetail {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.v1-trending .ProductDesc {
  display: inline-block;
  width: calc(50% - 60px);
  vertical-align: top;
  padding-left: 30px;
  padding-right: 30px;
}
.v1-trending .ProductDesc h5 + p {
  min-height: 390px;
}
.learnMore {
  text-align: center;
}
.learnMore a {
  background: #683bb7;
  color: #f2f4fd;
  border-radius: 5px;
  height: 40px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  padding-left: 18px;
  padding-right: 18px;
  margin-right: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  text-transform: none;
}
.learnMore a:hover {
  background: #4d288f;
  color: #f2f4fd;
}
.v1-trending .ProductDetailPage .ProductDetails {
  display: inline-block;
  width: 100%;
}
.v1-trending .ProductDetailPage .ProductDetails div {
  border-radius: 10px;
  height: 77px;
  margin-right: 30px;
  width: calc(33.33% - 68px);
  display: inline-block;
  padding: 24px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  text-align: center;
  color: #3d4465;
  vertical-align: top;
}
.v1-trending .ProductDetailPage .ProductDetails div:last-child {
  margin-right: 0px !important;
}
.v1-trending .ProductDetailPage .ProductDetails div span {
  display: inline-block;
  width: 100%;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 10px;
}
.v1-trending .ProductDetailPage .ProductDetails div:nth-child(1) {
  background: rgba(61, 68, 101, 0.1);
}
.v1-trending .ProductDetailPage .ProductDetails div:nth-child(2) {
  background: rgba(253, 78, 93, 0.1);
}
.v1-trending .ProductDetailPage .ProductDetails div:nth-child(2) span {
  color: #fd4e5d;
}
.v1-trending .ProductDetailPage .ProductDetails div:nth-child(3) {
  background: rgba(6, 157, 109, 0.1);
}
.v1-trending .ProductDetailPage .ProductDetails div:nth-child(3) span {
  color: #069d6d;
}
.v1-trending .ProductDetailPage .producImage img {
  object-position: center center;
  height: 80%;
  margin-top: 7.5vh;
  object-fit: contain;
  width: 90%;
}
.mb-30 {
  margin-bottom: 30px;
}
.v1-trending .ProductDetailHead {
  margin-top: 0px;
  margin-bottom: 20px;
}
.v1-trending .ProductDetailHead h5,
.v1-trending .Competitors h5 {
  font-weight: 500;
  font-size: 30px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
  display: inline-block;
}
.v1-trending .editText {
  align-items: flex-start;
}
.v1-trending .editText .editTextRow {
  width: 80%;
  display: inline-block;
}
.v1-trending .editText .userCol {
  width: 20%;
  display: inline-block;
  text-align: right;
}
.v1-trending .editText .userCol button {
  display: inline-block;
  width: 100%;
  border: 1px solid rgba(59, 78, 223, 0.1);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  color: rgba(61, 68, 101, 0.7);
  margin-bottom: 10px;
  height: 50px;
  background: #ffffff;
  padding-left: 15px;
  width: 300px;
  text-align: left;
  cursor: pointer;
}
.v1-trending .editText .userCol button img {
  margin-right: 45px;
  vertical-align: middle;
  width: 40px;
}
.v1-trending .calTable .editText .editTextRow {
  width: calc(33.33% - 30px);
  display: inline-block;
  margin-right: 30px;
}
.v1-trending .calTable .editText .editTextRow > div {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.v1-trending .editText .editTextRow > div.col-50 {
  width: calc(50% - 30px);
  display: inline-block;
  margin-right: 30px;
}
.v1-trending .editText .editTextRow > div.col-50 > div {
  display: inline-block;
  width: calc(100% - 30px);
  margin-bottom: 10px;
}
.v1-trending .editText .userCol + .shbox {
  padding: 25px;
  width: calc(100% - 50px);
  display: inline-block;
  height: auto;
  min-height: 100%;
  margin-top: 30px;
}
.v1-trending .editText .userCol + .shbox h5 {
  font-weight: 700;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  display: inline-block;
  width: 100%;
}
.v1-trending .editText .userCol + .shbox p {
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  margin-top: 2px;
  margin-bottom: 0px;
}
.v1-trending .editText .editTextRow > div.col-50 .iconsRow {
  margin-bottom: 0px !important;
}
.v1-trending .editText .editTextRow > div.col-50 .iconRow > div {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-bottom: 0px !important;
}
.v1-trending .editText > div > img {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.v1-trending .editText .editTextRow > div.col-50 .iconsRow > div {
  width: 50%;
  display: inline-block;
  position: relative;
  margin-bottom: 0px !important;
  white-space: nowrap;
}
.v1-trending .editText .editTextRow > div.col-50 .iconsRow > div img,
.v1-trending .editText .editTextRow > div.col-50 .iconRow > div img,
.v1-trending .Competitors .editTextRow .col-33.iconFields > div img {
  position: absolute;
  left: 75%;
  top: 5px;
  cursor: pointer;
  height: 40px;
}
.v1-trending .editText .editTextRow > div.col-50 .iconsRow > div > div,
.v1-trending .editText .editTextRow > div.col-50 .iconRow > div > div {
  width: 100%;
  display: inline-block;
  margin-bottom: 0px !important;
}
.v1-trending .editText .editTextRow div.col-50:first-child div:first-child {
  margin-bottom: 30px;
}
.v1-trending .editText .editTextRow div.col-50:first-child div:first-child + a {
  position: absolute;
  left: 250px;
  top: 12px;
}
.v1-trending .editText .editTextRow div label,
.v1-trending .Competitors .editTextRow .col-33 div label {
  display: inline-block;
  width: 210px;
  position: relative;
  transform: translate(0, 0px) scale(1) !important;
  vertical-align: top;
  margin-top: 0px;
}
.v1-trending .calTable .editText .editTextRow div label + div input,
.v1-trending .Competitors .editTextRow .col-33 div label + div input {
  width: calc(100% - 20px);
}
.v1-trending .editText .editTextRow div label + div,
.v1-trending .Competitors .editTextRow .col-33 div label + div {
  display: inline-block;
  width: calc(100% - 230px);
  position: relative;
  transform: translate(0, 0px) scale(1) !important;
  vertical-align: top;
  margin-top: 0px;
}
.v1-trending .Competitors .editTextRow .col-33 > div,
.v1-trending .Competitors .editTextRow .col-33 > div > div {
  width: 100%;
  display: inline-block;
}
.v1-trending .editText .editTextRow div label,
.v1-trending .Competitors .editTextRow .col-33 div label {
  background: #ebedfc;
  border-radius: 5px 0px 0px 5px;
  color: rgba(61, 68, 101, 0.7);
  font-weight: normal;
  font-size: 16px;
  height: 50px;
  padding-left: 20px;
  line-height: 50px;
}
.v1-trending .editText .editTextRow label {
  /* display:inline-block; */
  width: calc(40% - 20px);
}
.v1-trending .editText .editTextRow label > span:last-child {
  background: #ebedfc;
  border-radius: 5px 0px 0px 5px;
  color: rgba(61, 68, 101, 0.7);
  font-weight: normal;
  font-size: 16px;
  height: 50px;
  padding-left: 20px;
  line-height: 50px;
  width: 100%;
}
.v1-trending .editText .editTextRow label + button {
  border: 1px solid #3b4edf;
  height: 40px;
  vertical-align: top;
  margin-left: 10%;
  margin-top: 5px;
}
.v1-trending .editText .editTextRow div label + div input,
.v1-trending .Competitors .editTextRow .col-33 div label + div input {
  background: #ffffff;
  border: 1px solid rgba(59, 78, 223, 0.1);
  border-radius: 0px 5px 5px 0px;
  padding-left: 20px;
  height: 35px;
}
.v1-trending .editText .editTextRow div label + div::after,
.v1-trending .editText .editTextRow div label + div::before,
.v1-trending .Competitors .editTextRow .col-33 div label + div::before,
.v1-trending .Competitors .editTextRow .col-33 div label + div::after {
  display: none;
}
.v1-trending .Competitors .editTextRow .col-33 {
  width: calc(25% - 15px);
  display: inline-block;
  margin-right: 15px;
  margin-top: 20px;
}
.v1-trending .Competitors .editTextRow .col-33.iconFields {
  width: calc(70% - 15px);
  /* margin-left: 10px; */
  display: inline-block;
  margin-right: 0px;
}
.v1-trending .Competitors .editTextRow .col-33.iconFields > div {
  width: 33.33%;
  display: inline-block;
  position: relative;
}
.v1-trending .Competitors,
.v1-trending .calTable {
  padding: 30px;
  position: relative;
}
.v1-trending .Competitors .iconRowDel {
  position: absolute;
  right: 15px;
  color: #3b4edf;
  top: 15px;
  cursor: pointer;
}
.v1-trending .Competitors .iconRowEdit {
  position: absolute;
  right: 45px;
  color: #3b4edf;
  top: 15px;
  cursor: pointer;
}
.v1-trending .Competitors button.iconRowDel svg path,
.v1-trending .Competitors button.iconRowEdit svg path {
  color: #3b4edf;
}
.v1-trending .Competitors table,
.v1-trending .supplyOverviewTable table {
  margin-left: 0px;
  margin-right: 0px;
  width: calc(100% - 0px);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  margin-top: 20px;
}
.v1-trending .supplyOverviewTable table {
  margin: 30px;
  width: calc(100% - 60px);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}
.v1-trending .Competitors table th,
.v1-trending .supplyOverviewTable table th {
  background: rgba(59, 78, 223, 0.1);
  color: #3d4465;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  line-height: 27px;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
  text-align: center;
  position: relative;
}
.v1-trending .Competitors table th {
  border-left: 0.5px solid rgba(0, 0, 0, 0.2);
  border-right: 0.5px solid rgba(0, 0, 0, 0.2);
}
.v1-trending .Competitors table th:first-child {
  text-align: left;
  border-left: none;
}
.v1-trending .Competitors table th:last-child {
  border-right: none;
}
.v1-trending .Competitors table th img {
  height: 50px;
}
.v1-trending .Competitors table th button {
  position: absolute;
  right: 5px;
  color: #3b4edf;
}
.v1-trending .Competitors table th button svg path {
  color: #3b4edf;
}
.v1-trending .Competitors table th p {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3b4edf;
  display: block;
  width: 100%;
}
.v1-trending .Competitors table td,
.v1-trending .supplyOverviewTable table td {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  border: none;
  height: 34px;
  text-align: center;
}
.v1-trending .Competitors table td:first-child {
  text-align: left;
}
.v1-trending .Competitors table td:first-child {
  white-space: nowrap;
}
.v1-trending .Competitors table td button {
  cursor: pointer;
  margin-right: 15px;
  vertical-align: middle;
}
.v1-trending .Competitors table tr:nth-child(even),
.v1-trending .supplyOverviewTable table tr:nth-child(even) {
  background: rgba(59, 78, 223, 0.05);
}
.v1.filterChoicePopup * {
  font-family: "Poppins", sans-serif !important;
}
.v1.filterChoicePopup div[data-test="sentinelStart"] + div {
  padding: 30px;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
}
div[role="presentation"][id="menu-"] {
  z-index: 99999 !important;
}
.v1-trending .Competitors table td input {
  border: 1px solid #d6d7da;
  border-radius: 5px;
  height: 25px;
  padding-left: 5px;
}
.v1.filterChoicePopup button[class*="Primary"] {
  border-radius: 5px !important;
  height: 40px;
  font-size: 16px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border: 1px solid #3b4edf !important;
  text-transform: none !important;
  box-shadow: 0px 3px 3px #ccc !important;
  background: #3b4edf !important;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
}
.v1.filterChoicePopup div[role="tablist"] button {
  background: #ebedfc;
  border-radius: 5px;
  color: #3d4465;
  height: 40px;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Poppins", sans-serif !important;
}
.v1.filterChoicePopup button[class*="selected"] {
  color: #fff !important;
  background: #3b4edf !important;
}
.editPopup div[data-test="sentinelStart"] + div,
.addCompPopup div[data-test="sentinelStart"] + div,
.filterPopupYT div[data-test="sentinelStart"] + div {
  background: #fff;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
  margin-top: 5%;
  position: relative;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  padding: 30px;
  opacity: 1 !important;
  visibility: visible !important;
  left: auto;
  right: auto;
  transform: none;
  top: 0px;
}
.YTpopup div[data-test="sentinelStart"] + div {
  background: #fff;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1600px;
  margin-top: 5%;
  position: relative;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  padding: 30px;
  opacity: 1 !important;
  visibility: visible !important;
  left: auto;
  right: auto;
  transform: none;
  top: 0px;
}
.YTDiv {
  width: calc(33.33% - 57px);
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  border: 1px solid rgba(61, 68, 101, 0.3);
  border-radius: 20px;
  padding: 20px;
}
.YTGraph {
  width: calc(66.66% - 57px);
  margin-left: 15px;
  display: inline-block;
  border: 1px solid rgba(61, 68, 101, 0.3);
  border-radius: 20px;
  padding: 20px;
  text-align: center;
}
.YTGraph div[role="group"] + div {
  min-height: 405px !important;
}
.YTGraph div[role="group"] + div > div,
.YTGraph div[role="group"] + div > div svg {
  /* height: 405px !important; */
}
.filterPopupYT,
.YTpopup {
  z-index: 9999 !important;
}
.YTpopup {
  overflow-y: auto;
}
.filterPopupYT div[data-test="sentinelStart"] + div > div label {
  margin-bottom: 10px;
}
.filterPopupYT div[data-test="sentinelStart"] + div > div label + div {
  margin-bottom: 20px;
}
.filterPopupYT div[data-test="sentinelStart"] + div button[class*="Primary"] {
  height: 36px;
  margin-left: 15px;
}
.likeTabs .tracker td img {
  cursor: pointer;
}
.YTscroll {
  width: 100%;
  display: inline-block;
  max-height: 450px;
}
.YTscroll iframe {
  width: 100%;
  height: 300px;
  border-radius: 20px;
}
.YTscroll h6 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
}
.likeTabs .tracker {
  margin-top: 20px !important;
}
.YTscroll p {
  margin-bottom: 5px;
  margin-top: 10px;
  font-family: "Poppins", sans-serif !important;
  color: rgba(61, 68, 101, 0.6);
}
.YTscroll p a {
  width: calc(100% - 135px);
  display: inline-block;
  vertical-align: top;
  color: rgba(61, 68, 101, 0.6);
}
.YTscroll p span {
  font-weight: bold;
  min-width: 130px;
  /* text-align: left; */
  display: inline-block;
  font-family: "Poppins", sans-serif !important;
  color: rgba(61, 68, 101, 0.8);
}
.YTscroll button {
  margin-right: 10px;
  height: auto !important;
  margin-top: 10px;
  margin-bottom: 20px;
}
.editPopup div[data-test="sentinelStart"] + div h4 + div {
  max-height: 400px;
  overflow-y: auto;
}
/* .addCompPopup div[data-test="sentinelStart"] + div h4 + div
{

} */
.addCompPopup form fieldset {
  opacity: 0;
}
.addCompPopup h6 {
  margin-bottom: 10px;
}
.addCompPopup form input {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: block;
  width: calc(100% - 28px);
}
.addCompPopup form > div label {
  top: -7px;
}
.addCompPopup form > div,
.addCompPopup form > div > div {
  width: 100%;
  display: inline-block;
  margin-bottom: 6px;
}
.addCompPopup .rmsc.multi-select {
  margin-bottom: 20px;
}
div.editPopup div.dropdown-content {
  z-index: 99999;
  top: 100px !important;
  position: relative !important;
}
div.editPopup div.dropdown-content div {
  overflow: visible !important;
}
.editPopup div[data-test="sentinelStart"] + div h4 + div form div {
  width: 100%;
}
.editPopup div[data-test="sentinelStart"] + div h4,
.addCompPopup div[data-test="sentinelStart"] + div h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 20px;
}
.editPopup div[data-test="sentinelStart"] + div div[class*="TextField"],
.editPopup div[data-test="sentinelStart"] + div div[class*="TextField"] div {
  width: 100%;
  display: inline-block;
  margin-top: 0px;
}
.editPopup div[data-test="sentinelStart"] + div div::after,
.editPopup div[data-test="sentinelStart"] + div div::before {
  display: none;
}
.editPopup div[data-test="sentinelStart"] + div label {
  color: #ddd;
  left: 20px;
  top: 10px;
  transform: translate(0px, 0px) scale(0.75) !important;
}
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#supplier-type[data-shrink="true"]
  + div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#drop-shipping[data-shrink="true"]
  + div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#incoterms[data-shrink="true"]
  + div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#country[data-shrink="false"]
  + div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#country[data-shrink="true"]
  + div {
  border: 1px solid #d6d7da;
  border-radius: 5px;
  min-height: 40px;
  width: calc(100% - 2px);
  padding: 20px;
  margin-bottom: 15px;
}
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#supplier-type[data-shrink="true"]
  + div
  > div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#drop-shipping[data-shrink="true"]
  + div
  > div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#incoterms[data-shrink="true"]
  + div
  > div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#country[data-shrink="false"]
  + div
  > div {
  margin-top: 10px;
}
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#supplier-type[data-shrink="true"]
  + div
  div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#drop-shipping[data-shrink="true"]
  + div
  div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#incoterms[data-shrink="true"]
  + div
  div,
.editPopup
  div[data-test="sentinelStart"]
  + div
  form
  label#country[data-shrink="false"]
  + div
  div {
  padding: 0px !important;
}
.editPopup div[data-test="sentinelStart"] + div form div fieldset {
  display: none;
}
.editPopup div[data-test="sentinelStart"] + div input,
.editPopup div[data-test="sentinelStart"] + div textarea {
  border: 1px solid #d6d7da;
  border-radius: 5px;
  height: 40px;
  width: calc(100% - 44px);
  padding: 20px;
  margin-bottom: 15px;
}
.editPopup .jodit-react-container {
  margin-bottom: 15px;
}
.editPopup .jodit-workplace {
  background: #fff;
}
.editPopup div[data-test="sentinelStart"] + div button[class*="Primary"],
.addCompPopup div[data-test="sentinelStart"] + div button[class*="Primary"] {
  background: #3b4edf;
  border-radius: 5px !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
}
.addCompPopup svg[data-testid="CloseIcon"] {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #000;
}
.editPopup button.editCloseBtn {
  position: absolute;
  background: rgba(59, 78, 223, 0.1) !important;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #3b4edf;
  border: none;
  height: 30px !important;
  margin-left: 10px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  right: 30px;
  cursor: pointer;
  top: 30px;
  z-index: 9999;
}
.addlogisticBtn {
  position: relative;
  background: rgba(59, 78, 223, 0.1) !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  line-height: 30px;
  color: #3b4edf;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  top: -4px;
  margin-left: 15px;
}
.AccessLoginPopup {
  text-align: center;
}
.AccessLoginPopup .v1-loginBox {
  margin-bottom: 10%;
}
.AccessLoginPopup div[data-test="sentinelStart"] + div {
  display: inline-block;
  text-align: center;
  width: 100%;
  max-width: 450px;
  box-shadow: 0px 4px 5px 2px rgb(0 0 0 / 20%) !important;
  border: 1px solid #ddd !important;
  border-radius: 10px;
  background: #fff;
  position: relative;
  margin-top: 80px;
}
/* .addSupplierBtn
{
  position: absolute;
  background: rgba(59, 78, 223, 0.1)!important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  line-height: 30px;
  color: #3B4EDF;
  border: none;
  cursor:pointer;
  border-radius: 5px;
  top: -55px;
  margin-left: 175px;
} */
.editPopup button.editCloseBtn svg {
  margin-top: 2px;
}
iframe {
  z-index: 0 !important;
}
.v1-trending .plans,
.PlansPopup .plans {
  background: #f3f4f7;
  padding-bottom: 30px;
}
.PlansPopup div[data-test="sentinelStart"] + div {
  padding: 0px;
  zoom: 0.9;
}
.v1-trending .plans h1 {
  font-size: 44px;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  margin-top: 100px;
  font-weight: 400;
  margin-bottom: 0px;
}
.PlansPopup .plans h2 {
  font-size: 25px;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  margin-top: 40px;
  font-weight: 400;
  margin-bottom: 0px;
}
.PlansPopup .plans h2 + p {
  font-size: 14px;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 0px;
}
.PlansPopup .plans h1 {
  font-size: 44px;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  margin-top: 0px;
  font-weight: 400;
  margin-bottom: 0px;
}
.v1-trending .plans h1 + p {
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-bottom: 50px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}
.PlansPopup .plans h1 + p {
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}
.v1-trending .planBoxs,
.PlansPopup .planBoxs {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.v1-trending .planBox.selected,
.PlansPopup .planBox.selected {
  background: #fafbfa !important;
  border: 1px solid #fafbfa !important;
}
.v1-trending .planBox,
.PlansPopup .planBox {
  margin: 5px;
  max-width: 316px;
  width: 100%;
  display: inline-block;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 14px;
  text-align: left;
  height: 500px;
  vertical-align: top;
  position: relative;
  overflow: hidden;
}
.v1-trending .planBox .tophead,
.PlansPopup .planBox .tophead {
  background: #3b4edf;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  /* padding-left: 25px; */
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
.v1-trending .planBox h2,
.PlansPopup .planBox h2 {
  color: #3b4edf;
  font-weight: 400;
  font-size: 30px;
  font-family: "Poppins", sans-serif !important;
  margin-top: 28px;
  margin-bottom: 10px;
  width: 100%;
  display: inline-block;
  line-height: 20px;
  text-align: left;
}
.v1-trending .planBox h2 + p,
.PlansPopup .planBox h2 + p {
  text-align: left;
  margin-bottom: 15px;
}
.v1-trending .planBox button:disabled,
.PlansPopup .planBox button:disabled {
  border: 1px solid #3b4edf;
  border-radius: 6px;
  height: 44px;
  padding-left: 18px;
  padding-right: 18px;
  background: transparent;
  color: #3b4edf;
}
.v1-trending .planBox button,
.PlansPopup .planBox button {
  border-radius: 6px;
  height: 44px;
  padding-left: 18px;
  padding-right: 18px;
  background: transparent;
  background: #3b4edf;
  border: none;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
  cursor: pointer;
}
.v1-trending .planBox h5,
.PlansPopup .planBox h5 {
  color: #808f87;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  display: inline-block;
}
.v1-trending .planBox span,
.PlansPopup .planBox span {
  color: #a2a8a5;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  margin-top: 0px;
  margin-bottom: 0px;
  width: auto;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
}
.v1-trending .planBox span:hover,
.PlansPopup .planBox span:hover {
  background: #3b4edf;
  color: #fff;
  border: 1px solid #3b4edf;
}
.v1-trending .planBox p,
.PlansPopup .planBox p {
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: inline-block;
  font-family: "Poppins", sans-serif !important;
  margin-top: 0px;
  margin-bottom: 25px;
}
.v1-trending .planBox hr,
.PlansPopup .planBox hr {
  border-color: #d9dddb;
  margin-top: 20px;
  margin-bottom: 20px;
}
.v1-trending .planBox table,
.PlansPopup .planBox table {
  display: inline-block;
  width: 100%;
}
.v1-trending .planBox table td,
.PlansPopup .planBox table td {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
  color: #80848f;
  padding-top: 7px;
  padding-bottom: 7px;
}
.v1-trending .planBox table td img,
.PlansPopup .planBox table td img {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.viewCheckbox {
  position: absolute;
  left: 300px;
  top: -55px;
  font-size: 12px;
}
.viewCheckbox span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.viewFeaturedCheckbox {
  position: absolute;
  left: 300px;
  top: -30px;
  font-size: 12px;
}
.viewFeaturedCheckbox span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
div[aria-labelledby="simple-tab-8"] .bestSeller {
  padding: 0px !important;
  position: relative;
}
div[aria-labelledby="simple-tab-8"] .bestSeller .hoverIcon {
  right: 3px !important;
  top: -3px;
}
.subCard {
  background: #ebedfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 15px;
  width: 186px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  left: 40px;
  bottom: 50px;
  min-height: 160px;
}
.subCard button {
  background: #3b4edf;
  border: 1px solid #683bb7;
  border-radius: 4px;
  width: 100%;
  display: block;
  color: #fff;
  height: 34px;
  margin-bottom: 10px;
}
.subCard h4 {
  width: 100%;
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #3d4465;
  text-align: center;
  margin-bottom: 10px;
}
.subCard b {
  width: 100%;
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #3d4465;
  text-align: center;
  margin-top: 10px;
}
.Notifications {
  position: relative;
}
.NotificationsPopup {
  position: absolute;
  right: 0px;
  top: 90px;
  width: 390px;
  padding: 12px 2px 30px 20px;
  background: #ffffff;
  box-shadow: 0px -1px 10px rgba(243, 243, 243, 0.05),
    0px 20px 40px rgba(98, 101, 119, 0.2);
  border-radius: 10px;
}
.NotificationsPopup > h3 {
  font-weight: 500;
  font-size: 12px;
  color: #152c5b;
  display: inline-block;
  width: calc(100% - 100px);
}
.NotificationsPopup > button {
  padding: 0px;
  background: transparent;
  border: none;
  color: #267ff5;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
.notifyscroll {
  display: inline-block;
  width: 100%;
  overflow-y: auto;
  max-height: 600px;
  border-top: 1px solid #f3f3f3;
  padding-top: 15px;
  margin-top: 10px !important;
}
.Notifylist {
  display: inline-block;
  width: calc(100% - 20px);
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
  margin-bottom: 10px;
  vertical-align: top;
}
.Notifylist > div {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  line-height: 45px;
}
.Notifylist > div svg {
  width: 100%;
  text-align: center;
}
.Notifylist span {
  display: inline-block;
  width: calc(100% - 50px);
  vertical-align: top;
}
.Notifylist h3 {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #152c5b;
  font-weight: normal;
}
.Notifylist.unread h3 {
  font-weight: 700;
}
.Notifylist p {
  display: inline-block;
  width: 100%;
  font-size: 11px;
  vertical-align: top;
  margin-bottom: 0;
  color: #152c5b;
  margin-top: 5px;
}
.SearchBrandList {
  width: 100%;
  display: inline-block;
}
.addFilters {
  white-space: nowrap;
}

#brandTable div[class*="grid-xs-12"] h4 + div {
  /* width:100%; */
}
.OverviewTab .reportOverview {
  padding-top: 30px;
  padding-bottom: 20px;
}
.OverviewTab .reportOverview div div[role="button"] {
  font-size: 15px;
  border: 1px solid rgba(61, 68, 101, 0.2);
  border-radius: 4px 0px 0px 4px;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 25px;
  background: #fff;
}
.OverviewTab .reportOverview div fieldset {
  opacity: 0;
}
.OverviewTab .reportOverview button {
  font-size: 15px;
  border: 1px solid rgba(61, 68, 101, 0.2);
  border-radius: 0px 4px 4px 0px;
  height: 37px;
  padding-top: 5px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 25px;
  background: #fff;
  border-left: none;
}
.likeTabs {
  width: 100%;
  display: inline-block;
  position: relative;
}
.likeTabs div {
  display: inline-block;
  width: 100%;
}
.likeTabs div p {
  display: inline-block;
  width: auto;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  position: relative;
  color: rgba(61, 68, 101, 0.6);
}
.likeTabs div p::after {
  position: absolute;
  content: "";
  right: 0px;
  border-right: 1px solid #ccc;
  top: 10px;
  height: 30px;
}
.likeTabs div p span {
  font-weight: 600;
  font-size: 16px;
  color: #3d4465;
  padding-top: 5px;
  display: inline-block;
}
.likeTabs > button {
  position: absolute;
  right: 0px;
}
.featuredProducts {
  max-width: calc(100% + 30px) !important;
  display: inline-block;
  width: calc(100% + 30px) !important;
  margin-left: -15px;
}
.featuredProduct {
  max-width: 25% !important;
  display: inline-block;
  width: 100%;
  margin-bottom: 25px !important;
  position: relative;
  vertical-align: middle;
}
.featuredProduct > div {
  background: #ffffff;
  box-shadow: 0px 5px 20px 2px rgb(23 3 89 / 8%);
  border-radius: 10px;
  display: block;
  height: auto;
}
.featuredProduct > div > .hoverIcon + button {
  position: absolute;
  top: 50px;
  z-index: 9;
  right: 25px;
  padding: 0px;
  height: 20px;
  background: transparent;
}
.featuredProduct > div > .hoverIcon + button svg {
  right: 32px;
  fill: rgba(103, 58, 183, 0.8);
  stroke: rgba(103, 58, 183, 0.8);
}
.featuredProduct .hoverIcon {
  top: 40px;
  left: 20px;
}
.featuredProduct h6[class*="subtitle2"] {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3d4465;
  font-family: "Poppins", sans-serif !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: auto;
  margin-bottom: 5px !important;
  text-align: center;
  display: none;
}
.featuredProduct:hover h6[class*="subtitle2"] {
  display: block;
}
.featuredProduct .bstSellerDetails h6[class*="subtitle2"] {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3d4465;
  max-width: 200px;
  font-family: "Poppins", sans-serif !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: auto;
  margin-bottom: 5px !important;
  text-align: left;
  display: block;
}
.featuredProduct div[class*="Avatar-square"] {
  height: 240px;
  background: #f0ebf8;
  border-radius: 5px;
}
.featuredProduct .bstSellerDetails {
  display: inline-block;
  width: 65%;
  margin-top: 20px;
}
.featuredProduct .bstSellerDetails span a {
  font-size: 16px;
  line-height: 24px;
  color: #3d4465;
}
.featuredProduct .bstSellerDetails + h6 {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  font-family: "Poppins", sans-serif !important;
  color: #3b4edf;
  display: inline-block;
  width: 35%;
  text-align: right;
}
.featuredProduct .feauredIcons {
  text-align: center;
  width: 100%;
  display: inline-block;
  display: none;
  margin-bottom: 20px;
}
.featuredProduct:hover .feauredIcons {
  display: block;
}
.featuredProduct .feauredIcons button {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: rgba(59, 78, 223, 0.1);
  border: none;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding: 0px;
}
.featuredProduct button[class*="Primary"] {
  background: #3b4edf !important;
  border-radius: 5px !important;
  min-height: 40px;
  height: 40px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  display: block;
  padding: 0px;
  display: none;
}
.featuredProduct:hover > div {
  top: -15px;
  position: relative;
  box-shadow: 0px 5px 20px 2px rgba(23, 3, 89, 0.08);
}
.featuredProduct:hover button[class*="Primary"] {
  display: block;
}
.featuredProduct div button[class*="Primary"] span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
}
.TrendingAd.adsTab {
  max-width: calc(100% + 30px) !important;
  margin-left: -15px;
  width: calc(100% + 30px) !important;
}
.TrendingAds {
  position: relative;
  z-index: 99999;
  max-width: 25% !important;
  flex-basis: content;
  position: relative;
}
.featuredProducts.adsTab > .adItems {
  margin: 15px;
  max-width: calc(25% - 30px) !important;
}
.TrendingAds:hover > div {
  max-width: calc(100% - 30px) !important;
  top: -15px;
  left: -15px;
  flex-basis: calc(100% - 30px) !important;
  width: calc(100% - 30px) !important;
  position: relative;
  padding: 15px;
  box-shadow: 0px 5px 40px 3px rgba(59, 78, 223, 0.7);
}
.TrendingAds:hover .TrendingAdsImg {
  height: 330px;
}
.TrendingAds > div {
  padding: 10px;
  box-shadow: 0px 5px 20px 3px rgba(23, 3, 89, 0.08);
  border-radius: 10px;
  border: none;
}
.TrendingAdsImg {
  background: #c4c4c4;
  border-radius: 5px;
  height: 312px;
}
.TrendingAdsImg img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
}
.TrendingAdsDtails {
  display: inline-block;
  position: relative;
  width: 100%;
}
.TrendingAdsDtails div[class*="Avatar"] {
  width: 38px;
  height: 38px;
  border-radius: 50px;
  border: 1px solid #3b4edf;
  padding: 0px;
  margin-left: 5px;
  margin-top: 24px;
  display: inline-block;
  text-align: center;
}
.TrendingAdsDtails div[class*="Avatar"] img {
  width: 23.5px;
  height: 23.5px;
  border: 1px solid #3b4edf;
  border-radius: 50px;
  margin-top: 6px;
}
.TrendingAdsName {
  display: inline-block;
  width: calc(100% - 55px);
  margin-left: 8px;
}
.TrendingAdsName p {
  margin: 0px;
}
.TrendingAdsName p:first-child {
  font-size: 18px;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold;
}
.TrendingAdsName + i {
  position: absolute;
  right: 5px;
  top: 24px;
  border: 1px solid #3b4edf;
  border-radius: 50px;
  color: #3b4edf;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.TrendingAdsDtails + div {
  padding: 10px;
}
.TrendingAdsDtails + div span + div {
  float: right;
  position: relative;
  color: #3d4465;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
}
.TrendingAdsDtails + div span + div::after {
  position: absolute;
  background: #fff;
  width: 22px;
  height: 21px;
  border: 1px solid #069d6d;
  content: "";
  left: 0px;
  z-index: 0;
  top: 4px;
  border-radius: 2px;
}
.TrendingAdsDtails + div > div svg {
  fill: #069d6d;
  position: relative;
  top: 4px;
  z-index: 1;
}
.TrendingAdsDtails + div span {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3d4465;
}
.TrendingAdsharedIcons {
  display: flex;
  justify-content: space-between;
}
.TrendingAdsharedIcons p {
  font-weight: 400;
  font-size: 20px;
  color: rgba(61, 68, 101, 0.8);
  font-family: "Poppins", sans-serif !important;
  margin-left: 0px;
  display: inherit;
}
.TrendingAdsharedIcons p img {
  margin-right: 10px;
}
.TrendingAds button {
  background: rgba(59, 78, 223, 0.1) !important;
  border-radius: 5px !important;
  border: none !important;
  width: 152px !important;
}
.TrendingAds button span {
  color: #3b4edf !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
}
.TrendingAdsSort {
  display: flex;
  justify-content: flex-end;
  margin-top: -55px;
  margin-bottom: 10px;
}
.TrendingAdsSort button {
  height: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
  font-size: 16px;
  margin-right: 30px;
}
.TrendingAdsSort button span,
.TrendingAdsSort button label {
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
  font-size: 16px;
}
.TrendingAdsSort div[role="button"] {
  height: 40px;
  padding-top: 0px;
  padding-bottom: 0;
  line-height: 40px;
  font-family: "Poppins", sans-serif !important;
  color: #3d4465;
  font-size: 16px;
}
.TrendingAdsSort div[role="button"] fieldset {
  height: 40px;
}
@media only screen and (min-width: 2200px) {
  .featuredProducts.adsTab > .adItems {
    max-width: calc(20% - 30px) !important;
    min-width: calc(20% - 30px) !important;
  }
  .featuredProduct {
    max-width: 20% !important;
  }
}
@media only screen and (max-width: 1700px) {
  .v1-trending .planBox,
  .PlansPopup .planBox {
    max-width: 315px;
    padding: 20px;
    height: 525px;
  }
  .v1-trending .ActSettings .plans table td {
    font-size: 13px;
  }
  .v1-trending .planBox h2,
  .PlansPopup .planBox h2 {
    font-size: 20px;
  }
  .v1-trending .planBox .tophead,
  .PlansPopup .planBox .tophead {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .v1-trending .planBox button,
  .PlansPopup .planBox button {
    height: 30px;
    font-size: 13px;
  }
  .featuredProducts.adsTab > .adItems {
    max-width: calc(33.33% - 30px) !important;
    min-width: calc(33.33% - 30px) !important;
  }
}
@media only screen and (max-width: 1650px) {
  .v1-trending .planBox,
  .PlansPopup .planBox {
    max-width: 300px;
    padding: 20px;
    height: 525px;
  }
}
@media only screen and (max-width: 1575px) {
  .v1-trending .planBox,
  .PlansPopup .planBox {
    max-width: 290px;
    padding: 20px;
    height: 525px;
  }
}
@media only screen and (max-width: 1550px) {
  .v1-trending .planBox,
  .PlansPopup .planBox {
    max-width: 255px;
    padding: 10px;
    height: 525px;
  }
  .v1-trending .ActSettings .plans table td {
    font-size: 11px;
  }
  .v1-trending .planBox h2,
  .PlansPopup .planBox h2 {
    font-size: 20px;
  }
  .v1-trending .planBox .tophead,
  .PlansPopup .planBox .tophead {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .v1-trending .planBox button,
  .PlansPopup .planBox button {
    height: 30px;
    font-size: 13px;
  }
  .featuredProduct {
    max-width: 33.33% !important;
  }
}
@media only screen and (max-width: 1400px) {
  .v1-trending .searchBrandPaper h6 strong {
    font-size: 13px;
  }
  .v1-trending .SocialTab div[class*="Paper-elevation1"] h4,
  .v1-trending .SocialTab div[class*="Paper-outlined"] h4 {
    font-size: 20px;
    min-width: calc(100% - 222px);
  }
  .v1-trending .SocialTab div[class*="Paper-elevation1"] h6,
  .v1-trending .SocialTab div[class*="Paper-outlined"] h6 {
    font-size: 16px;
    min-width: 222px;
  }
  .v1-trending button[class*="outlinedSizeSmall"] span {
    font-size: 12px;
  }
  .v1-trending button[class*="outlinedSizeSmall"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .v1-trending div[aria-label="Brand tabs"] button[class*="selected"] span,
  .v1-trending div[aria-label="Brand tabs"] button span {
    font-size: 13px;
  }
  .v1-trending div[aria-label="Brand tabs"] button {
    margin-right: 15px;
  }
  .infoSocial > div span,
  .infoSocial > div a {
    font-size: 14px;
  }
  .v1-trending .reportTab .pbRptBtn {
    font-size: 13px;
  }
  .v1-trending .reportTab .ProductChartHead h6,
  .v1-trending .ProductChartHead h5,
  .v1-trending .ProductChartHead h5 span,
  .v1-trending .SerAdsHead h5,
  .v1-trending .KeywordChartHead h5 {
    font-size: 22px;
  }
  .v1-trending .StoreDataList li {
    font-size: 15px;
  }
  .v1-trending .StoreDataList li div span {
    font-size: 22px;
  }
  .v1-trending .tracker h6 {
    font-size: 18px;
    margin-bottom: 50px;
  }
  .v1-trending .reportTab .tracker .triggerBtn {
    position: absolute;
    right: 15px;
    top: 70px;
    width: calc(100% - 30px);
  }
  .v1-trending .tracker table {
    margin-left: 15px;
    margin-right: 15px;
  }
  .v1-trending .adsTab .adItems div[class*="Avatar"] + a,
  .v1-trending .reportTab .adItems div[class*="Avatar"] + a {
    font-size: 15px;
  }
  .v1-trending .adsTab .adItems div[class*="Avatar"] + a + p,
  .v1-trending .adsTab .adItems div[class*="Avatar"] + a + button + p,
  .v1-trending .reportTab .adItems div[class*="Avatar"] + a + p {
    font-size: 11px;
  }
  .v1-trending .adsTab .adsheader span.Active + a,
  .v1-trending .reportTab .adsheader span.Active + a,
  .v1-trending .adsTab .adsheader span.Inactive + a {
    height: 30px;
    min-width: 100px;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
  }
  .v1-trending .adsTab .adsheader > span,
  .v1-trending .reportTab .adsheader > span {
    width: calc(100% - 100px);
  }
  .v1-trending .adsTab .adsheader div.sdate span,
  .v1-trending .reportTab .adsheader div.sdate span {
    font-size: 12px;
  }
  .adsheader div.ldate + p {
    font-size: 12px;
  }
  .v1-trending .adsTab .adsheader div.sIcons,
  .v1-trending .reportTab .adsheader div.sIcons {
    width: 18px;
    height: 18px;
    line-height: 15px;
    margin-left: 4px;
  }
  .v1-trending .adsTab .adsheader div.sIcons i,
  .v1-trending .reportTab .adsheader div.sIcons i {
    font-size: 11px;
  }
  .v1-trending .adsTab .adItems > div,
  .v1-trending .reportTab .adItems > div {
    padding: 15px;
  }
  .v1-trending div[aria-label="Brand tabs"] button,
  .v1-trending .productTab button[class*="outlined"] {
    min-height: 30px;
    height: 30px;
    font-size: 12px;
  }
  .v1-trending .customSort {
    height: 30px;
    line-height: 30px;
  }
  .v1-trending div[aria-label="Brand tabs"] button[class*="selected"] {
    min-height: 30px;
    height: 30px;
  }
  .v1-trending .adsTab div[aria-haspopup="listbox"],
  .v1-trending .customSort label {
    font-size: 12px;
    padding-bottom: 6px;
    padding-top: 6px;
  }
  .v1-trending .adsTab .customSort {
    margin-left: 15px;
    box-shadow: none;
  }
  .v1-trending .GoogleAds .SerAds button span,
  .v1-trending .SerAdsHead .SerAds h4 a {
    font-size: 14px;
  }
  .v1-trending .SerAdsHead .SerAds h4 + a {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .v1-trending .SerAdsHead .SerAds > div {
    height: 180px;
  }
  .v1-trending .productBox h6[class*="subtitle2"],
  .v1-trending .bestSeller h6[class*="subtitle2"] {
    font-size: 15px;
    margin-bottom: 5px !important;
  }
  .v1-trending .productBox .pdtDetails + h6,
  .v1-trending .bestSeller .bstSellerDetails + h6 {
    font-size: 22px;
  }
  .v1-trending .planBox,
  .PlansPopup .planBox {
    max-width: 235px;
    padding: 10px;
    height: 560px;
  }
  .v1-trending .ActSettings .plans table td {
    font-size: 10px;
  }
  .v1-trending .planBox h2,
  .PlansPopup .planBox h2 {
    font-size: 20px;
  }
  .v1-trending .planBox .tophead,
  .PlansPopup .planBox .tophead {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .v1-trending .planBox button,
  .PlansPopup .planBox button {
    height: 30px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 640px) {
  .Iconlogo {
    width: 175px;
    margin-left: 60px;
    display: block;
  }
  .navBar.v1 div button[aria-label="close drawer"] {
    left: -275px;
  }
  .navBar.v1 button[aria-label="open drawer"] {
    left: 35px;
  }
  .Iconlogo-sm {
    display: none;
  }
  .navBar.v1 header::after {
    background: transparent;
  }
  .v1-trending .SerAdsHead .SerAds > div {
    margin-left: 0px !important;
    padding: 15px;
    width: calc(100% - 30px) !important;
    height: auto !important;
    padding-bottom: 0px !important;
  }
  .v1-trending .GoogleAds .SerAds button {
    width: calc(100% + 30px) !important;
    margin-left: -15px;
  }
  .v1-trending .SocialTab .apexchart > div {
    min-height: 470px !important;
    height: 200px !important;
    width: 100% !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .v1-trending .SocialTab .apexchart div div svg {
    min-height: 200px;
    max-height: 470px !important;
    max-width: 770px !important;
  }
  .v1-trending .SocialTab .apexchart > div > div {
    max-height: 470px;
    height: 200px !important;
    max-width: 770px !important;
  }
  .v1-trending .SocialTab .apexchart {
    width: calc(100% - 30px);
    padding: 15px;
    height: 200px;
  }
  .v1-trending .SerAds .keywordTab h5 {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .v1-trending .SerAds .keywordTab table th,
  .v1-trending .SerAds .keywordTab table td {
    font-size: 13px;
  }
  .SerAds button {
    margin-top: -15px !important;
  }
  .navBar.v1 div[class*="h6"] img {
    max-width: 175px;
    margin-left: 35px;
  }
  .navBar.v1 .loginBtn {
    margin-left: 0px;
  }
  .slider.navBar.v1 {
    z-index: 9999;
  }
  .v1-trending .searchHead h4 {
    font-size: 21px !important;
    padding: 20px;
  }
  .v1-trending .searchHead h5 {
    padding: 0px 20px;
  }
  .v1-trending .searchHead + h6 {
    padding: 0px 20px;
  }
  .v1-trending .productTabResult .productBox {
    max-width: calc(100%) !important;
    flex-basis: calc(100%) !important;
    /* margin-left: 15px !important; */
  }
  .productTabResult .proResult h6 + div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .navBar.v1 + div > div {
    margin-left: 0px;
    padding: 10px;
  }
  .slider.v1-trending {
    width: 100%;
  }
  .v1-trendingItems .resultBox {
    max-width: 100%;
    flex-basis: 100%;
    padding-right: 8px !important;
  }
  .v1-trending .reportTab > div > p,
  .v1-trending .reportTab > div > strong {
    width: 100%;
  }
  .navBar.v1 .searchBox {
    display: none;
  }
  .addFilters > div:first-child {
    display: contents;
  }
  .infoSocial {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-bottom: 20px !important;
  }
  .v1-trending .gaugeChart,
  .v1-trending .infoSocial + div {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-left: 0px !important;
  }
  .v1-trending .gaugeChart {
    margin-bottom: 20px !important;
  }
  .v1-trending .graphs .shbox {
    margin-right: 0px !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-bottom: 20px !important;
  }
  .navBar.v1 + div {
    padding: 0px 10px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .v1-trending::before {
    width: calc(100% + 40px);
    left: -10px;
  }
  .navBar.v1 + div > div {
    padding: 0px;
  }
  .v1-trending .ProductDetailPage,
  .v1-trending .ProductAnalysis {
    padding: 10px;
    margin-bottom: 0px;
  }
  .v1-trending .ProductDetailPage .producImage,
  .v1-trending .ProductDetailPage .ProductDetails,
  .v1-trending .ProductDetail {
    width: 100%;
  }
  .v1-trending .ProductDesc {
    width: calc(100% - 60px);
  }
  .v1-trending .ProductDesc h5 + p {
    min-height: 100%;
    margin-bottom: 20px;
  }
  .v1-trending .ProductDetailPage .ProductDetails div {
    margin-left: 0px;
    margin-right: 20px;
    width: calc(33.33% - 45px);
    padding: 15px;
    font-size: 12px;
    margin-bottom: 15px;
  }
  .v1-trending .ProductDetailPage .ProductDetails div:nth-child(3) {
    margin-right: 0px;
  }
  .v1-trending .ProductDetailPage .ProductDetails div span {
    font-size: 16px;
  }
  .ProductDetailPage h5,
  .v1-trending .ProductAnalysis h5,
  .v1-trending .ProductDetailHead h5,
  .v1-trending .Competitors h5 {
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 25px;
  }
  .ProductDetailPage p,
  .v1-trending .ProductAnalysis .ProductAnalysisDetails p {
    font-size: 13px;
  }
  .v1-trending .ProductAnalysis .ProductAnalysisDetails {
    width: 100%;
    margin-right: 0px;
  }
  .v1-trending .loader + div {
    padding-top: 1px;
  }
  .v1-trending .Competitors table th,
  .v1-trending .supplyOverviewTable table th,
  .v1-trending .Competitors table td,
  .v1-trending .supplyOverviewTable table td {
    font-size: 12px;
  }
  .v1-trending .ProductDetailPage,
  .v1-trending .ProductAnalysis,
  .v1-trending .shbox {
    /* box-shadow: none!important; */
  }
  .v1-trending .Competitors,
  .v1-trending .calTable {
    padding: 10px;
  }
  .searchBrandPaper {
    padding: 15px;
    margin: 15px;
  }
  .v1-trending .editText .editTextRow > div.col-50,
  .v1-trending .editText .editTextRow > div.col-50 > div,
  .v1-trending .editText .editTextRow > div.col-50 .iconsRow > div {
    width: 100%;
    display: inline-block;
    margin-right: 0px;
  }
  .v1-trending .editText .editTextRow > div.col-50 .iconsRow > div {
    margin-bottom: 10px !important;
  }
  .v1-trending
    .editText
    .editTextRow
    div.col-50:first-child
    div:first-child
    + a {
    position: absolute;
    left: 206px;
    top: 17px;
    font-size: 12px;
  }
  .v1-trending .editText .editTextRow {
    width: 100%;
    display: inline-block;
  }
  .v1-trending .editText .editTextRow div label,
  .v1-trending .Competitors .editTextRow .col-33 div label {
    width: 165px;
    font-size: 12px;
  }
  .v1-trending .editText .editTextRow div label + div,
  .v1-trending .Competitors .editTextRow .col-33 div label + div {
    width: calc(100% - 207px);
  }
  .v1-trending .editText .editTextRow div label + div input,
  .v1-trending .Competitors .editTextRow .col-33 div label + div input,
  .v1-trending .editText .userCol + .shbox p {
    font-size: 12px;
    width: 100%;
  }
  .v1-trending .editText .userCol + .shbox {
    padding: 10px;
    width: 100%;
    margin-top: 0px;
  }
  .v1-trending .editText .userCol,
  .v1-trending .Competitors .editTextRow .col-33.iconFields {
    width: 100%;
  }
  .v1-trending .editText .userCol button {
    width: 100% !important;
    font-size: 12px;
  }
  .v1-trending .editText .userCol button img {
    margin-right: 25px;
  }
  .v1-trending .Competitors .editTextRow .col-33.iconFields > div {
    width: 100%;
    margin-bottom: 10px;
  }
  .v1-trending .ProductAnalysis button.addSupplierBtn,
  .v1-trending .Competitors button.addSupplierBtn {
    right: auto;
    top: 42px;
    padding-left: 8px;
    padding-right: 8px;
    height: 25px;
    left: 10px;
    line-height: 21px;
    font-size: 11px;
  }
  .v1-trending .ProductAnalysis button {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 30px;
    font-size: 12px;
  }
  .v1-trending .supplyOverviewTable table {
    margin: 10px;
    width: calc(100% - 20px);
  }
  .v1-trending .ProductAnalysis button.selected {
    margin-top: 25px;
  }
  .searchBrandPaper hr {
    margin-left: -15px;
    width: calc(100% + 30px);
  }
  .editPopup div[data-test="sentinelStart"] + div,
  .addCompPopup div[data-test="sentinelStart"] + div {
    padding: 20px;
    width: calc(100% - 80px);
    margin: 20px;
  }
  .editPopup div[data-test="sentinelStart"] + div h4,
  .addCompPopup div[data-test="sentinelStart"] + div h4 {
    font-size: 20px;
  }
  .CountryGraph,
  .v1-trending .reportTab .shbox {
    max-width: 100%;
    flex-basis: 100%;
  }
  .addFilters > div {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 10px;
  }
  .v1-trending .countryAutoComplete,
  .countryRow > div {
    margin-left: 0px !important;
  }
  #brandTable h4 {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  #brandTable div h4 + div button {
    width: calc(50% - 29px);
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .MobileViewSearchBrand {
    width: calc(100% - 30px);
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  .MobileViewSearchBrand ul {
    padding: 0px;
    margin-top: 0px;
  }
  .MobileViewSearchBrand ul li {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    white-space: nowrap;
    min-height: 60px;
    line-height: 60px;
    transition: 1s all;
    position: relative;
  }
  .MobileViewSearchBrand ul li button {
    position: absolute;
    right: 15px;
    background: #3b4edf;
    cursor: pointer;
    border: 1px solid #3b4edf;
    font-size: 11px;
    border-radius: 3px;
    top: 20px;
    color: #fff;
  }
  .v1-trending + .BrandPagination div,
  .v1-trending + .BrandPagination label#page-select {
    display: none;
  }
  .MobileViewSearchBrand ul li:nth-child(even),
  .MobileViewSearchBrand ul li table tr:nth-child(odd) {
    background: rgba(59, 78, 223, 0.05);
  }
  .MobileViewSearchBrand ul li table tr td {
    height: 43px;
    border: none;
    font-size: 10px;
    white-space: normal;
  }
  .MobileViewSearchBrand ul li table tr td:first-child {
    padding-left: 20px !important;
    font-size: 12px;
    white-space: nowrap;
  }
  .MobileViewSearchBrand ul li table {
    margin-left: -30px;
    margin-bottom: 10px;
    width: calc(100% + 60px);
  }
  .v1-trending #brandTable tbody div[class*="valueChip"] span,
  .v1-trending #brandTable tbody div[class*="valueChip"] + span {
    font-size: 10px;
  }
  .v1-trending #brandTable tbody div[class*="valueChip"] + span {
    margin-left: 25px;
  }
  .MobileViewSearchBrand ul li > div {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    border: 1px solid #3b4edf;
  }
  .MobileViewSearchBrand h4 {
    padding-left: 0px;
    background: rgba(59, 78, 223, 0.1);
    border-radius: 10px 10px 0px 0px;
    height: 70px;
    padding-left: 30px !important;
    line-height: 70px;
    margin-bottom: 0px !important;
  }
  #brandTable div h4 + div a {
    width: calc(50% - 30px);
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 10px;
  }
  #brandTable div h4 + div a span {
    font-size: 12px;
  }
  .v1-trending .durationFilter > div > span + div > div div {
    top: 15px;
    left: 0px;
    font-size: 12px;
    white-space: nowrap;
    overflow: visible;
  }
  .durationFilter {
    /* width:100%; */
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .durationFilter > div {
    width: 95%;
  }
  .v1-trending + .BrandPagination {
    width: 100%;
  }
  .v1.filterChoicePopup div[data-test="sentinelStart"] + div {
    margin: 10px;
    width: calc(100% - 100px);
    margin-top: 50px;
  }
  .v1.filterChoicePopup div[role="tablist"] button {
    padding-left: 5px;
    padding-right: 5px;
    height: 25px;
  }
  .v1-trending .BrandInfoBtn {
    margin-top: 0px;
    width: 100%;
    right: 0px;
    position: static;
  }
  .v1-trending .productTab {
    margin-top: 20px !important;
  }
  .visitBtn {
    margin-right: 5px !important;
    margin-left: 5px !important;
    float: none;
  }
  .v1-trending .brandTitle > div {
    text-align: center;
    /* margin-top: 20px; */
  }
  .reportTab .pbRptBtn + div,
  .CountryGraph {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .CountryGraph > div + div {
    max-width: calc(100% - 0px) !important;
    flex-basis: calc(100% - 0px) !important;
    margin-left: 0px;
    margin-top: 15px;
  }
  .v1-trending .SerAdsHead > div {
    grid-gap: 15px;
  }
  .v1-trending .SerAdsHead .SerAds {
    flex-grow: 0;
    max-width: calc(100% - 0px);
    flex-basis: calc(100% - 0px);
    border: none !important;
  }
  .v1-trending .reportTab .pbRptBtn {
    top: -5px !important;
    right: 0px;
  }
  .v1-trending .SocialTab {
    margin-top: 0px;
  }
  .v1-trending .adsTab div[class*="Paper-rounded"] {
    z-index: 99;
    left: 15px;
    bottom: 15px;
    width: calc(100% - 60px);
    padding: 15px;
  }
  .v1-trending .reportTab .pbRptBtn + div {
    margin-top: 45px;
  }
  .ProductChartHead {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 100%;
    flex-basis: 100%;
  }
  .ProductChartHead + div {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 100%;
    flex-basis: 100%;
  }
  .StoreDataList li {
    width: calc(100% - 30px);
    margin: 15px;
  }
  .v1-trending .bestSeller > div {
    margin: 0px !important;
  }
  .v1-trending .reportTab .adItems {
    margin-left: 1px !important;
  }
  .v1-trending .BarchartTable {
    margin-left: 0px !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .v1-trending .ChartTable.tracker table {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    margin-top: 15px;
  }
  .v1-trending .productBox {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .v1-trending .productTab .BrandPagination > label,
  .v1-trending .productTab .BrandPagination > div {
    display: none !important;
  }
  .v1-trending .productTab .BrandPagination {
    background: transparent;
    padding: 0px;
    left: 0px;
    top: 0px;
  }
  .v1-trending + .BrandPagination nav {
    display: block;
    padding-left: 0px;
  }
  .v1-trending .productTab .BrandPagination nav {
    margin-left: 0px;
    margin-top: 20px;
  }
  #productItems {
    grid-gap: 15px;
  }
  /* .v1-trending .adsTab > div > div
  {
    max-width:calc(100% - 40px) !important;
    flex-basis: calc(100% - 40px) !important;
    margin-left: 0px;
  } */
  .adsTab > div {
    max-width: calc(100% - 0px) !important;
    flex-basis: calc(100% - 0px) !important;
    margin-left: 0px;
    padding: 0px;
    grid-gap: 15px;
  }
  .OverviewTab .reportOverview > div {
    max-width: calc(100% - 0px) !important;
    flex-basis: calc(100% - 0px) !important;
    margin-left: 0px;
    padding: 0px;
    justify-content: center;
  }
  .v1-trending .reportTab {
    margin: 0px !important;
    width: calc(100% + 0px) !important;
  }
  .v1-trending .adsTab {
    margin-top: 0px;
    margin-bottom: 25px !important;
  }
  .adsTab > div > div {
    max-width: calc(100% - 0px) !important;
    flex-basis: calc(100% - 0px) !important;
    margin-left: 0px;
  }
  .OverviewTab .reportOverview div div[role="button"] {
    font-size: 12px;
    border: 1px solid rgba(61, 68, 101, 0.2);
    border-radius: 4px 0px 0px 4px;
    height: 25px;
    padding-top: 5px;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 25px;
  }
  .OverviewTab .reportOverview div fieldset {
    opacity: 0;
  }
  .OverviewTab .reportOverview div button[tabindex="0"] {
    border: 1px solid rgba(61, 68, 101, 0.2);
    border-left: 0px !important;
    border-radius: 0px 4px 4px 0px;
  }
  .OverviewTab .reportOverview div[class*="Controller"] > div + button,
  .OverviewTab div[class*="grid-xs-8"] > div + button {
    padding-left: 10px;
    padding-right: 10px;
    height: 32px;
  }
  div[class*="Controller"] .reportOverview > div + button span,
  .OverviewTab div[class*="grid-xs-8"] > div + button span {
    white-space: nowrap;
    font-size: 12px;
  }
  .v1-trending .accessPop {
    width: calc(100% - 30px);
    padding: 15px;
  }
  .OverviewTab div[class*="countryDataPaper"] {
    left: 15px;
    width: calc(100% - 60px);
    padding: 15px;
  }
  .v1-trending .SocialTab > div {
    max-width: 100% !important;
    flex-basis: 100% !important;
    padding-left: 0px !important;
  }
  .v1-trending .adsTab h5,
  .v1-trending .OverviewTab h5 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .v1-trending .SocialTab button[class*="outlined"]:first-child {
    width: calc(100% - 155px) !important;
  }
  .ProductChartHead + div > div {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 15px !important;
  }
  .v1-trending .storeGraphBox {
    display: block;
    padding: 15px;
    height: auto;
    width: calc(100% - 30px) !important;
  }
  .v1-trending .storeGraphBox .apexcharts-canvas {
    width: 100% !important;
    height: 250px !important;
  }
  .v1-trending .storeGraphBox > div {
    max-width: 100% !important;
    flex-basis: 100% !important;
    height: auto;
  }
  .viewCheckboxDiv {
    height: 100px;
  }
  .viewCheckbox {
    position: absolute;
    left: 20px;
    top: 0px;
    font-size: 12px;
  }
  .searchHead + div[class*="ButtonsContainer"] {
    text-align: center;
    position: relative;
    z-index: 99;
  }
  .v1-trendingItems .sortProduct {
    white-space: normal;
    right: 5px;
    text-align: left;
    margin-left: 20px;
  }
  .sortProduct > label {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    width: 100%;
    text-align: left;
    padding-left: 5px;
    margin-top: -25px;
  }
  .v1-trendingItems .sortProduct .countryAutoComplete {
    max-width: 100%;
    width: auto;
  }
  .productTabResult .BrandPagination {
    height: 100px;
  }
  .productTabResult .BrandPagination .pageNumber {
    position: absolute;
    top: 45px;
    white-space: nowrap;
    display: inline-block;
    left: 15px;
    width: 50%;
    text-align: left;
  }
  .productTabResult .BrandPagination nav {
    margin-bottom: 10px;
  }
  .productTabResult .proResult > div {
    margin-right: 0px !important;
  }
  .sortedItems {
    text-align: left;
  }
  .uf-button-main {
    bottom: 10px !important;
    right: 33% !important;
  }
  .v1-trendingItems {
    width: calc(100% - 20px) !important;
    margin: 0px !important;
    margin-left: 10px !important;
  }
  .v1-trending {
    /* overflow-x: hidden; */
  }
  .featuredProducts.adsTab > .adItems {
    max-width: calc(100% - 55px) !important;
    min-width: calc(100% - 55px) !important;
    margin: 20px;
    margin-bottom: 0px;
  }
  .TrendingAdsSort {
    margin-top: 0px;
    justify-content: center;
  }
  .v1-trending .customSort {
    height: auto;
    line-height: 30px;
  }
  .featuredProducts .BrandPagination .pageNumber,
  .featuredProducts .BrandPagination label,
  .featuredProducts .BrandPagination label + div {
    display: none !important;
  }
  .featuredProducts .BrandPagination nav {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 0px;
  }
  .featuredProducts .BrandPagination {
    text-align: center;
  }
  .featuredProduct {
    max-width: 100% !important;
  }
  .featuredProduct > div {
    margin: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
